import { MutationConfig } from '@/types';
import * as BasketApi from '@/api/basket';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { BasketType } from '@/types/basket';
import { useCloned } from '@vueuse/core';
import { NormalizedBasket } from '@/model';

type CreateVoucherOptions = {
  config?: MutationConfig<typeof BasketApi.changeQuantity>;
};

const useUpdateQuantity = (basketType: BasketType, { config }: CreateVoucherOptions = {}) => {
  const queryClient = useQueryClient();
  const basketItemProperty = basketType === 'TakeawayBasket' ? 'takeawayBasketItem' : 'basketItem';
  return useMutation({
    onMutate: ({ basketId, itemId, quantity }) => {
      queryClient.cancelQueries(queries.baskets.byId(basketId));
      const oldBasketItem = queryClient.getQueryData<NormalizedBasket>(
        queries.baskets.byId(basketId).queryKey
      )?.[basketItemProperty]?.[itemId];

      queryClient.setQueryData<NormalizedBasket>(
        queries.baskets.byId(basketId).queryKey,
        basket => {
          const { cloned: tempBasket } = useCloned(basket, { manual: true });
          if (tempBasket.value?.[basketItemProperty]?.[itemId])
            tempBasket.value[basketItemProperty]![itemId].attributes.quantity = quantity;
          return tempBasket.value;
        }
      );

      return { oldBasketItem };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, { basketId, itemId }, { oldBasketItem }: any) => {
      return queryClient.setQueriesData<NormalizedBasket>(
        queries.baskets.byId(basketId),
        basket => {
          if (basket?.[basketItemProperty]?.[itemId])
            basket[basketItemProperty]![itemId] = oldBasketItem;
          return basket;
        }
      );
    },
    onSuccess: (result, { basketId }) => {
      queryClient.setQueryData<NormalizedBasket>(queries.baskets.byId(basketId).queryKey, result);
    },
    onSettled: (_, __, { basketId }) => {
      queryClient.invalidateQueries(queries.baskets.byId(basketId));
    },
    ...config,
    mutationFn: BasketApi.changeQuantity,
  });
};

export default useUpdateQuantity;
