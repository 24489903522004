import currentCustomerStore from '@/stores/currentCustomerStore';
import { trackCustomEvent } from '@/utils/analytics';
import { ObjectToCamel } from '@pasta-evangelists/pasta-types';
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.data) {
    config.data = decamelizeKeys(config.data);
  }

  return config;
}

export const client = Axios.create({
  baseURL: process.env.VITE_API_BASE_URL,
  withCredentials: true,
  headers: {
    'x-branch': 'PNIH',
  },
});

client.interceptors.request.use(authRequestInterceptor);
client.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type']?.includes('application/json')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = camelizeKeys(response.data as any);
    }
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      currentCustomerStore().$reset();
    } else if (isAxiosError<{ message: string }>(error)) {
      trackCustomEvent('error_reported', {
        error_type: error.response?.data?.message,
      });
    }
    throw error;
  }
);

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<ObjectToCamel<T>> => {
  const source = Axios.CancelToken.source();
  if (config?.url?.startsWith('/api/v2/')) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [, newUrl] = config?.url.split('/api/v2/');
    config.url = newUrl;
  }
  const promise = client({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = ObjectToCamel<BodyData>;

export default client;
