import normalize from '@/utils/normalize';
import { getApiV2RestaurantsIdMenu } from './pensa';

export const getTakeawayMenu = async (
  restaurantId: string | number,
  fulfillmentType: 'delivery' | 'pickup' | 'eat_in' | null
) => {
  try {
    const data = await getApiV2RestaurantsIdMenu(restaurantId.toString(), {
      fulfillment_type: fulfillmentType || undefined,
    });

    return normalize(data);
  } catch (e) {
    throw new Error("Couldn't fetch menu");
  }
};
