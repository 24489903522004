import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as BasketApi from '@/api/basket';
import { MutationConfig } from '@/types';
import usePastaNowBasket from '@/stores/pastaNowBasketStore';
import { queries } from '@/api/queries';

type RemoveItemsFromBasketOptions = {
  config?: MutationConfig<typeof BasketApi.removeItemsFromBasket>;
};

const useRemoveItemsFromBasket = ({ config }: RemoveItemsFromBasketOptions = {}) => {
  const queryClient = useQueryClient();
  const pastaNowBasket = usePastaNowBasket();

  const restaurantId = computed(() => pastaNowBasket.restaurantId);

  return useMutation({
    ...config,
    mutationFn: BasketApi.removeItemsFromBasket,
    onSuccess: (result, { basketId }) => {
      queryClient.setQueriesData(queries.baskets.byId(basketId), result);
      queryClient.invalidateQueries(queries.baskets.byId(basketId));
      queryClient.invalidateQueries(queries.takeaway.restaurant(restaurantId));
    },
  });
};

export default useRemoveItemsFromBasket;
