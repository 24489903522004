import { useQuery } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { getApiV2RestaurantsIdSnoozedProducts } from '@/api/pensa';

const useGetSnoozedProducts = (
  restaurantId: Ref<string | null>,
  fulfillmentType?: Ref<'delivery' | 'pickup' | 'eat_in' | null>
) => {
  const enabled = computed(() => !!restaurantId.value);
  const type = fulfillmentType?.value ?? undefined;
  return useQuery({
    ...queries.takeaway.restaurant(restaurantId)._ctx.snoozedProducts(fulfillmentType),
    queryFn: async () => {
      const result = await getApiV2RestaurantsIdSnoozedProducts(restaurantId.value!, {
        fulfillment_type: type,
      });
      return result.data;
    },
    enabled,
    refetchInterval: 30 * 1000,
  });
};

export default useGetSnoozedProducts;
