import { acceptHMRUpdate, defineStore } from 'pinia';
import useOrdersStore from './ordersStore';
import dayjs from 'dayjs';
import useSubscriptionStore from './subscriptionStore';
import useGetScheduleWeeks from '@/api/queries/pensa/useGetScheduleWeeks';
import { NormalizedOrder, ScheduledOrder } from '@/model';

const transformOrderIntoScheduledOrder = (order: NormalizedOrder): ScheduledOrder => {
  return {
    id: '',
    type: 'subscription_schedule_week',
    attributes: {
      billingDate: '',
      deliveryDate: order.attributes.deliveryDate || '',
      originalDeliveryDate: null,
      startDate: order.attributes.deliveryDate || '',
      state: 'active',
    },
    relationships: {
      order: {
        data: {
          id: order.id,
          type: 'order',
        },
      },
      subscription: {
        data: {
          id: '',
          type: 'subscription',
        },
      },
    },
  };
};

const useScheduleStore = defineStore('scheduledStore', () => {
  const {
    data: normalizedScheduledOrders,
    isLoading: loading,
    error,
    refetch: getSchedule,
  } = useGetScheduleWeeks();

  const scheduledOrders = computed<Record<string, ScheduledOrder[]> | null>(() => {
    if (!normalizedScheduledOrders.value?.subscriptionScheduleWeek) return null;
    return Object.values(normalizedScheduledOrders.value.subscriptionScheduleWeek).reduce(
      (acc, curr) => {
        if (acc[curr.attributes.deliveryDate]) {
          acc[curr.attributes.deliveryDate].push(curr);
        } else {
          acc[curr.attributes.deliveryDate] = [curr];
        }
        return acc;
      },
      {} as Record<string, ScheduledOrder[]>
    );
  });

  const futureScheduledOrders = computed<Record<string, ScheduledOrder[]> | null>(() => {
    const ordersStore = useOrdersStore();
    if (!scheduledOrders.value && !ordersStore.upcomingNormalizedOneOffs) return null;

    const result = scheduledOrders.value
      ? Object.entries(scheduledOrders.value).reduce((acc, [date, scheduledOrder]) => {
          const scheduledOrdersList = scheduledOrder.filter(
            order =>
              ['active', 'skipped'].includes(order.attributes.state) &&
              dayjs(dayjs(order.attributes.deliveryDate)).isAfter(dayjs().subtract(1, 'day'))
          );
          if (scheduledOrdersList.length) {
            acc[date] = scheduledOrdersList;
          }
          return acc;
        }, {} as Record<string, ScheduledOrder[]>)
      : {};
    if (ordersStore.upcomingNormalizedOneOffs) {
      Object.values(ordersStore.upcomingNormalizedOneOffs).forEach(oneOff => {
        if (result[oneOff.attributes.deliveryDate || ''])
          result[oneOff.attributes.deliveryDate || ''].push(
            transformOrderIntoScheduledOrder(oneOff)
          );
        else
          result[oneOff.attributes.deliveryDate || ''] = [transformOrderIntoScheduledOrder(oneOff)];
      });
    }
    if (ordersStore.upcomingNormalizedAmendedOrders) {
      Object.values(ordersStore.upcomingNormalizedAmendedOrders).forEach(order => {
        if (result[order.attributes.deliveryDate || ''])
          result[order.attributes.deliveryDate || ''].push(transformOrderIntoScheduledOrder(order));
        else
          result[order.attributes.deliveryDate || ''] = [transformOrderIntoScheduledOrder(order)];
      });
    }
    if (!Object.keys(result).length) return null;
    return result;
  });

  const ordersInScheduledOrders = computed<Record<string, ScheduledOrder> | null>(() => {
    if (!normalizedScheduledOrders.value?.subscriptionScheduleWeek) return null;
    return Object.values(normalizedScheduledOrders.value.subscriptionScheduleWeek).reduce(
      (acc, item) => {
        if (item.relationships.order.data) {
          acc[item.relationships.order.data.id] = item;
        }
        return acc;
      },
      {} as Record<string, ScheduledOrder>
    );
  });

  const futureScheduledOrderForActiveSub = computed<Record<string, ScheduledOrder[]> | null>(() => {
    if (!scheduledOrders.value) return null;
    const subscriptionStore = useSubscriptionStore();
    const result = Object.entries(scheduledOrders.value).reduce((acc, [date, scheduledOrder]) => {
      const scheduledOrdersList = scheduledOrder.filter(
        order =>
          ['active', 'skipped'].includes(order.attributes.state) &&
          dayjs(dayjs(order.attributes.deliveryDate)).isAfter(dayjs().subtract(1, 'day')) &&
          order.relationships.subscription.data?.id === subscriptionStore.selectedId
      );
      if (scheduledOrdersList.length) {
        acc[date] = scheduledOrdersList;
      }
      return acc;
    }, {} as Record<string, ScheduledOrder[]>);
    if (!Object.keys(result).length) return null;
    return result;
  });

  const activeFutureWeeksForActiveSub = computed<Record<string, ScheduledOrder[]> | null>(() => {
    if (!scheduledOrders.value) return null;
    const subscriptionStore = useSubscriptionStore();
    const result = Object.entries(scheduledOrders.value).reduce((acc, [date, scheduledOrder]) => {
      const scheduledOrdersList = scheduledOrder.filter(
        order =>
          order.attributes.state === 'active' &&
          dayjs(dayjs(order.attributes.deliveryDate)).isAfter(dayjs().subtract(1, 'day')) &&
          order.relationships.subscription.data?.id === subscriptionStore.selectedId
      );
      if (scheduledOrdersList.length) {
        acc[date] = scheduledOrdersList;
      }
      return acc;
    }, {} as Record<string, ScheduledOrder[]>);
    if (!Object.keys(result).length) return null;
    return result;
  });

  const activeFutureWeeks = computed<Record<string, ScheduledOrder[]> | null>(() => {
    if (!scheduledOrders.value) return null;
    const result = Object.entries(scheduledOrders.value).reduce((acc, [date, scheduledOrder]) => {
      const scheduledOrdersList = scheduledOrder.filter(
        order =>
          order.attributes.state === 'active' &&
          dayjs(dayjs(order.attributes.deliveryDate)).isAfter(dayjs().subtract(1, 'day'))
      );
      if (scheduledOrdersList.length) {
        acc[date] = scheduledOrdersList;
      }
      return acc;
    }, {} as Record<string, ScheduledOrder[]>);
    if (!Object.keys(result).length) return null;
    return result;
  });

  const getScheduleIdByDate = (deliveryDate: string, subId: string) => {
    if (normalizedScheduledOrders.value) {
      const scheduleWeek = Object.values(
        normalizedScheduledOrders.value.subscriptionScheduleWeek
      ).find(
        schedule =>
          schedule.attributes.deliveryDate === deliveryDate &&
          schedule.relationships.subscription.data?.id === subId
      );
      return scheduleWeek ? scheduleWeek.id : '';
    }
    return '';
  };

  return {
    normalizedScheduledOrders,
    loading,
    error,
    scheduledOrders,
    futureScheduledOrders,
    ordersInScheduledOrders,
    futureScheduledOrderForActiveSub,
    activeFutureWeeksForActiveSub,
    activeFutureWeeks,
    getScheduleIdByDate,
    getSchedule,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useScheduleStore, import.meta.hot));

export default useScheduleStore;
