import { ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { BodyType } from './client';
import { isAxiosError } from 'axios';
import {
  OrderCreationResponse,
  PostApiV2DeliveryEstimationBody,
  PostApiV2OrdersGuestBody,
  PostApiV2OrdersOpenTabCheckoutBody,
  PostApiV2OrdersTakeawayBody,
  postApiV2DeliveryEstimation,
  postApiV2OrdersGuest,
  postApiV2OrdersOpenTabCheckout,
  postApiV2OrdersTakeaway,
} from './pensa';

interface ApiResponseSuccess<T> {
  data: ObjectToCamel<T>;
  error: null;
}

export interface InvalidBasketApiResponseError {
  data: null;
  error: {
    message: string;
    code: string;
    errors: Record<string, string[]> | null;
  };
}

export declare type ValidateBasketResponse<T> = Promise<
  ApiResponseSuccess<T> | InvalidBasketApiResponseError
>;

export const getDeliveryEstimation = async (
  params: ObjectToCamel<PostApiV2DeliveryEstimationBody>
) => {
  try {
    const data = await postApiV2DeliveryEstimation(params);
    return data;
  } catch (e) {
    throw new Error("Couldn't get delivery time estimation");
  }
};

export const createOrder = async (
  params: BodyType<PostApiV2OrdersTakeawayBody>
): ValidateBasketResponse<OrderCreationResponse> => {
  try {
    const data = await postApiV2OrdersTakeaway(params);

    return { data, error: null };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: 'Failed while trying to create order', code: '500', errors: null },
    };
  }
};

export const payOpenTab = async (
  params: BodyType<PostApiV2OrdersOpenTabCheckoutBody>
): ValidateBasketResponse<OrderCreationResponse> => {
  try {
    const result = await postApiV2OrdersOpenTabCheckout(params);
    return { data: result, error: null };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: 'Failed while trying to pay your order', code: '500', errors: null },
    };
  }
};

export const createGuestOrder = async (
  params: BodyType<PostApiV2OrdersGuestBody>
): ValidateBasketResponse<OrderCreationResponse> => {
  try {
    const result = await postApiV2OrdersGuest(params);

    return { data: result, error: null };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: 'Failed while trying to create order', code: '500', errors: null },
    };
  }
};
