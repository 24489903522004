import { Normalize, notEmpty, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { Order } from '@/api/pensa';
import { CustomerVoucher, NormalizedOrder, TransformedProduct } from '@/model';

export const normalizeTakeawayOrder = (
  orderId: string,
  normalizedOrder: Normalize<ObjectToCamel<Order>>
): NormalizedOrder => {
  const order = normalizedOrder.order[orderId];

  const products: TransformedProduct[] = order.relationships.lineItems.data
    .filter(
      lineItem =>
        Boolean(normalizedOrder!.lineItem?.[lineItem.id]) &&
        !normalizedOrder.lineItem![lineItem.id].attributes.parentLineItem
    )
    .map(lineItem => ({
      ...normalizedOrder!.lineItem![lineItem.id].attributes,
      id: normalizedOrder!.lineItem![lineItem.id].id,
      imageUrl: normalizedOrder!.lineItem![lineItem.id].attributes.imageUrl || '',
      productRating:
        normalizedOrder!.lineItem![lineItem.id].relationships.productRating?.data || null,
      productVariantId: normalizedOrder!.lineItem![lineItem.id]!.attributes.productVariantId,
      subItems: normalizedOrder!.lineItem![lineItem.id].relationships.subItems.data.map(
        subItem => normalizedOrder!.lineItem![subItem.id]
      ),
    }));

  const billingAddressObject = order.relationships.orderBillingAddress.data
    ? normalizedOrder.orderAddress?.[order.relationships.orderBillingAddress.data.id]
    : null;
  const billingAddress = billingAddressObject
    ? { id: billingAddressObject.id, ...billingAddressObject.attributes }
    : null;

  const shippingAddressObject = order.relationships.orderShippingAddress.data
    ? normalizedOrder.orderAddress?.[order.relationships.orderShippingAddress.data.id]
    : null;
  const deliveryAddress = shippingAddressObject
    ? { id: shippingAddressObject.id, ...shippingAddressObject.attributes }
    : null;

  const customerId = order.relationships.customer.data?.id;

  const customerVoucherObject = order.relationships.customerVoucher.data
    ? normalizedOrder.customerVoucher?.[order.relationships.customerVoucher.data?.id]
    : null;
  let customerVoucher: CustomerVoucher | null = null;
  const voucherId = customerVoucherObject?.relationships.voucher.data?.id;
  if (customerVoucherObject && normalizedOrder.voucher && voucherId) {
    customerVoucher = {
      id: customerVoucherObject.id,
      attributes: customerVoucherObject.attributes,
      voucher: { id: voucherId, attributes: normalizedOrder.voucher[voucherId].attributes },
    };
  }

  const restaurantObject = order.relationships.restaurant?.data
    ? normalizedOrder.restaurant?.[order.relationships.restaurant.data.id]
    : null;
  const restaurant = restaurantObject
    ? { id: restaurantObject.id, ...restaurantObject.attributes }
    : null;

  const shipmentObject = order.relationships.shipment.data
    ? normalizedOrder.shipment?.[order.relationships.shipment.data.id]
    : null;
  const shipment = shipmentObject ? { id: shipmentObject.id, ...shipmentObject.attributes } : null;

  const shipmentItems =
    shipmentObject?.relationships.items.data
      ?.map(shipmentItemId => {
        if (!normalizedOrder.shipmentItem) return null;
        const shipmentItem = normalizedOrder.shipmentItem[shipmentItemId.id];
        return {
          id: shipmentItem.id,
          description: shipmentItem.attributes.description,
          price: shipmentItem.attributes.price,
          threshold: shipmentItem.attributes.threshold,
          root: shipmentItem.attributes.root,
        };
      })
      .filter(notEmpty) || [];

  const feedbackObject = order.relationships.orderFeedback.data
    ? normalizedOrder.orderFeedback?.[order.relationships.orderFeedback.data.id]
    : null;
  const feedback = feedbackObject
    ? {
        id: feedbackObject.id,
        npsRating: feedbackObject.attributes.npsRating,
        reason: feedbackObject.attributes.reason,
        notes: feedbackObject.attributes.notes,
      }
    : null;

  const refunds =
    order.relationships.refunds.data
      ?.map(refundId => {
        if (!normalizedOrder.refund) return null;
        const shipmentItem = normalizedOrder.refund[refundId.id];
        return {
          id: shipmentItem.id,
          amount: shipmentItem.attributes.amount,
          state: shipmentItem.attributes.state,
          processorId: shipmentItem.attributes.processorId,
        };
      })
      .filter(notEmpty) || [];

  const { totalDiscounts, ...rest } = order.attributes;
  const attributes = { ...rest, discount: totalDiscounts };

  return {
    id: orderId,
    attributes,
    products,
    billingAddress,
    deliveryAddress,
    feedback,
    customerVoucher,
    restaurant,
    shipment,
    shipmentItems,
    scheduledWeek: null,
    subscription: null,
    customerId,
    refunds,
  };
};

export const renderModifierText = (appliedModifiers: { title: string | null }[]) => {
  return appliedModifiers
    .filter(notEmpty)
    .map(modifier => modifier.title)
    .sort((a, b) => a?.localeCompare(b || '') || 1)
    .join(', ');
};
