import * as DeliveryApi from '@/api/delivery';
import dayjs from 'dayjs';
import { createDeliveryDates, isSunday } from '@/utils/date';
import utc from 'dayjs/plugin/utc';
import useBasketStore from '../stores/basketStore';
import useWeeklyMenuStore from '../stores/weeklyMenusStore';
import intersection from 'lodash.intersection';
import useGetDeliveryDates from '@/api/queries/pensa/useGetDeliveryDates';

interface DeliveryDateParam {
  zip?: Ref<string | null>;
}

const useDeliveryDates = (params?: DeliveryDateParam) => {
  dayjs.extend(utc);

  const weeklyStore = useWeeklyMenuStore();
  const basketStore = useBasketStore();

  const now = dayjs().format('YYYY-MM-DD');
  const DATES = new Array(21).fill(0).map((_, index) => {
    return dayjs(now).add(index, 'days').format('YYYY-MM-DD');
  });

  const postcode = computed(() => {
    if (params?.zip?.value) return params.zip.value;
    return basketStore.address?.zip;
  });

  const firstAvailableDate = computed(() => {
    return availableDates.value.find(date => {
      return DATES.includes(date.value) && !date.disabled;
    });
  });

  const firstMenuByDate = computed(() => {
    return Object.keys(basketStore.itemsInMenus).sort((a, b) => +new Date(a) - +new Date(b))[0];
  });

  const basketItems = computed(() => {
    if (!basketStore.normalizedBasket || !basketStore.normalizedBasket.basketItem) return [];
    return Object.values(basketStore.normalizedBasket.basketItem)
      .filter(item => !item.attributes.isAddon)
      .map(item => ({
        id: item.attributes.productVariantExtId,
      }));
  });

  const productVariantIds = computed(() => {
    if (!basketStore.normalizedBasket || !basketStore.normalizedBasket.basketItem) return [];
    return Object.values(basketStore.normalizedBasket.basketItem).map(
      item => item.attributes.productVariantId
    );
  });

  const weeksThatShouldHaveDates = computed(() => {
    const numberOfMenusInBasket = Object.keys(basketStore.itemsInMenus || {}).length;

    if (
      numberOfMenusInBasket === 0 ||
      !weeklyStore.productsWithWeeklyMenus ||
      !Object.keys(weeklyStore.productsWithWeeklyMenus).length
    )
      return [];

    if (numberOfMenusInBasket > 1) {
      return [firstMenuByDate.value];
    }

    return intersection(
      ...basketItems.value.map(item => weeklyStore.productsWithWeeklyMenus![item.id!]),
      weeklyStore.availableWeeks
    ).sort((a, b) => +new Date(a) - +new Date(b));
  });

  const beginDate = computed(() => {
    return dayjs(weeksThatShouldHaveDates.value[0]).set('hours', 13).utc().format('YYYY-MM-DD');
  });

  const endDate = computed(() => {
    return weeksThatShouldHaveDates.value.length > 1
      ? dayjs(weeksThatShouldHaveDates.value[weeksThatShouldHaveDates.value.length - 1])
          .add(6, 'day')
          .set('hours', 13)
          .utc()
          .format('YYYY-MM-DD')
      : undefined;
  });

  const deliveryDatesQueryParams = computed(() => ({
    beginDate: beginDate.value,
    endDate: endDate.value,
    postcode: postcode.value ? postcode.value : undefined,
    productVariantIds: productVariantIds.value,
  }));

  const { data, isLoading, refetch } = useGetDeliveryDates(deliveryDatesQueryParams);

  const unavailableDatesBecauseOfProducts = computed(() => {
    if (!data.value) return [];
    const datesAux = data.value.unavailableProducts.reduce((acc, unavailableObject) => {
      for (const menuWeek of weeksThatShouldHaveDates.value) {
        const listOfProductIds = basketStore.productIdsInMenus[menuWeek];
        if (listOfProductIds && listOfProductIds.includes(unavailableObject.product)) {
          acc.push(...unavailableObject.dates);
        }
      }
      return acc;
    }, [] as string[]);

    return [...new Set(datesAux)];
  });

  const availableDates = computed(() => {
    return getAvailableDates(data.value).filter(date => !isSunday(date.value));
  });

  const getAvailableDates = (data: DeliveryApi.GetDeliveryDatesServerResponse | undefined) => {
    const weeks = basketStore.hasItems
      ? weeksThatShouldHaveDates.value
      : weeklyStore.availableWeeks;
    const allDates = weeks.reduce((acc, item) => {
      const createdDatesForMenu = createDeliveryDates({
        ignoreCutoff: true,
        excludeSunday: false,
        from: item,
      });
      return [
        ...acc,
        ...createdDatesForMenu.map(delivery => dayjs(delivery.date).format('YYYY-MM-DD')),
      ];
    }, [] as string[]);

    return allDates.map(d => ({
      formatted: dayjs(d).format('dddd Do MMM'),
      value: dayjs(d).format('YYYY-MM-DD'),
      disabled:
        data?.unavailableDates.includes(d) || unavailableDatesBecauseOfProducts.value.includes(d),
    }));
  };

  const disabledDates = computed(() => {
    return availableDates.value.filter(date => date.disabled).map(date => date.value);
  });

  return {
    isLoading,
    availableDates,
    productVariantIds,
    firstAvailableDate,
    disabledDates,
    retry: refetch,
    getAvailableDates,
  };
};

export default useDeliveryDates;
