import useCurrentCustomerStore from '@/stores/currentCustomerStore';
import { getOneRandomPastOrder } from '@/api/orders';
import { defineStore } from 'pinia';
import { Normalize, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { Orders } from '@/api/pensa';

const useClientStatusStore = defineStore('clientStatusStore', () => {
  const customerStore = useCurrentCustomerStore();
  const isLoading = ref(false);
  const data = ref<Normalize<ObjectToCamel<Orders>> | null>(null);

  const hasOrders = computed(() => Object.values(data.value?.order ?? {}).length > 0);

  watch(
    () => customerStore.email,
    async email => {
      if (email) {
        isLoading.value = true;
        const result = await getOneRandomPastOrder();
        isLoading.value = false;
        if (result.error) return;
        data.value = result.data;
      }
    }
  );
  return { hasOrders };
});

export default useClientStatusStore;
