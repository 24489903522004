import * as OrdersApi from '@/api/orders';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { MutationConfig } from '@/types';

type UpdateOrderOptions = {
  config?: MutationConfig<typeof OrdersApi.updateUpcomingOrders>;
};

const useUpdateOrder = ({ config }: UpdateOrderOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(queries.orders.scheduleWeeks);
      await queryClient.invalidateQueries(queries.subscriptions.all);
      return await queryClient.invalidateQueries(queries.orders.upcoming);
    },
    ...config,
    mutationFn: OrdersApi.updateUpcomingOrders,
  });
};

export default useUpdateOrder;
