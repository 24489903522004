import type { ApiResponse, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import normalize from '@/utils/normalize';
import client, { BodyType } from './client';
import {
  BasketItemPayload,
  GetApiV2BasketsBasketIdVouchersParams,
  PostApiV2BasketsBasketIdVouchersBody,
  PostApiV2BasketsBody,
  PutApiV2BasketsBasketIdItemsIdBody,
  PutApiV2BasketsIdBody,
  deleteApiV2BasketsBasketIdItemsId,
  deleteApiV2BasketsBasketIdItemsMany,
  deleteApiV2BasketsBasketIdVouchersId,
  deleteApiV2BasketsId,
  getApiV2Baskets,
  getApiV2BasketsBasketIdVouchers,
  getApiV2BasketsId,
  getApiV2BasketsIdValidate,
  postApiV2Baskets,
  postApiV2BasketsBasketIdItems,
  postApiV2BasketsBasketIdVouchers,
  putApiV2BasketsBasketIdItemsId,
  putApiV2BasketsId,
} from './pensa';
import { decamelizeKeys } from 'humps';
import { ValidateBasketResponse } from './takeawayBasket';
import { isAxiosError } from 'axios';

export interface ItemParams {
  basketId: string;
  params: BodyType<BasketItemPayload>;
}

export const getBaskets = async () => {
  const result = await getApiV2Baskets();
  return normalize(result);
};

export const getBasketById = async (id: string) => {
  const result = await getApiV2BasketsId(id);
  return normalize(result);
};

export const addItem = async ({ basketId, params }: ItemParams) => {
  const result = await postApiV2BasketsBasketIdItems(basketId, params);

  return normalize(result);
};

type ChangeQuantityParams = {
  basketId: string;
  itemId: string;
} & BodyType<PutApiV2BasketsBasketIdItemsIdBody>;

export const changeQuantity = async ({ basketId, itemId, quantity }: ChangeQuantityParams) => {
  const result = await putApiV2BasketsBasketIdItemsId(basketId, itemId, { quantity });

  return normalize(result);
};

export const removeItem = async (params: { basketId: string; itemId: string }) => {
  const result = await deleteApiV2BasketsBasketIdItemsId(params.basketId, params.itemId);
  return normalize(result);
};

export const createBasket = async (params: BodyType<PostApiV2BasketsBody>) => {
  const result = await postApiV2Baskets(params);

  return normalize(result);
};

export interface VoucherParams {
  basketId: string;
  params: BodyType<PostApiV2BasketsBasketIdVouchersBody>;
}

export const addVoucher = async ({ basketId, params }: VoucherParams) => {
  try {
    const result = await postApiV2BasketsBasketIdVouchers(basketId, {
      ...params,
      code: params.code.toUpperCase(),
    });

    return normalize(result);
  } catch (e: any) {
    const message = e.response?.status === 400 ? e.response.data.message : 'Invalid code';
    throw new Error(message);
  }
};

export const deleteVoucher = async (params: { basketId: string; voucherId: string }) => {
  const result = await deleteApiV2BasketsBasketIdVouchersId(params.basketId, params.voucherId);

  return normalize(result);
};

type ValidateVoucherParams = {
  basketId: string;
} & ObjectToCamel<GetApiV2BasketsBasketIdVouchersParams>;

export const validateVoucher = async ({ basketId, ...params }: ValidateVoucherParams) => {
  try {
    const result = await getApiV2BasketsBasketIdVouchers(basketId, decamelizeKeys(params));

    return normalize(result);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message =
      e.response?.status === 400 ? e.response.data.message : "Couldn't validate voucher";
    throw new Error(message);
  }
};

interface RemoveItemsFromBasketParams {
  basketId: string;
  ids: string[];
}

export const removeItemsFromBasket = async ({ basketId, ids }: RemoveItemsFromBasketParams) => {
  const result = await deleteApiV2BasketsBasketIdItemsMany(basketId, { ids });

  return normalize(result);
};

export type UpdateBasketParams = {
  id: string;
  params: BodyType<PutApiV2BasketsIdBody>;
};

export const updateBasket = async ({ id, params }: UpdateBasketParams) => {
  const result = await putApiV2BasketsId(id, params);

  return normalize(result);
};

interface ValidateBasketParams {
  basketId: string;
  validateFully?: boolean;
}

export const validateBasket = async ({
  basketId,
  validateFully = false,
}: ValidateBasketParams): ValidateBasketResponse<true> => {
  try {
    await getApiV2BasketsIdValidate(basketId, { validate_fully: validateFully.toString() });
    return { data: true, error: null };
  } catch (e: unknown) {
    if (isAxiosError(e) && e.response?.status === 400 && e.response?.data) {
      if (typeof e.response.data.error === 'string') {
        return {
          data: null,
          error: { message: e.response.data.message, code: '400', errors: null },
        };
      }
      return { data: null, ...e.response?.data };
    }
    return {
      data: null,
      error: { message: "Couldn't validate basket", code: '500', errors: null },
    };
  }
};

export const deleteBasket = async (id: string) => {
  try {
    await deleteApiV2BasketsId(id);
    return true;
  } catch (e) {
    throw new Error("Couldn't delete basket");
  }
};

export const registerFingerprint = async (fingerprint: string): ApiResponse<true> => {
  try {
    await client.post('customers/fingerprint', { fingerprint });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: "Couldn't register fingerprint" } };
  }
};
