/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * PENSA API
 * OpenAPI spec version: v2
 */
import { customInstance } from './client';
import type { BodyType } from './client';
export type PostApiV2TakeawayMenuProductsUnsnoozeBody = {
  product_ids?: string[];
};

export type PostApiV2TakeawayMenuProductsSnoozeBody = {
  product_ids?: string[];
};

export type PutApiV2SubscriptionsIdBody = {
  beef?: boolean;
  billing_address_id?: string;
  cocktails?: boolean;
  crustaceans?: boolean;
  delivery_note?: string;
  double_portion?: boolean;
  fish?: boolean;
  game?: boolean;
  gluten_free?: boolean;
  lamb?: boolean;
  molluscs?: boolean;
  name?: string;
  number_of_cocktails?: number;
  number_of_recipes?: number;
  nuts?: boolean;
  payment_method_id?: string;
  pork?: boolean;
  recipe_variety?: boolean;
  shipping_address_id?: string;
  vegan?: boolean;
  vegetarian?: boolean;
};

export type PostApiV2SubscriptionsBodyScheduleAttributes = {
  delivery_day: string;
  first_delivery: string;
  frequency: number;
};

export type PostApiV2SubscriptionsBody = {
  basket_id?: string;
  billing_address_id: string;
  delivery_note: string;
  schedule_attributes: PostApiV2SubscriptionsBodyScheduleAttributes;
  shipping_address_id: string;
};

export type PostApiV2SubscriptionsSubscriptionIdPauseBody = {
  pause_note?: string;
  pause_reason: string;
};

export type PostApiV2SubscriptionsChangePaymentMethodBody = {
  new_payment_method_id: string;
  old_payment_method_id?: string;
};

export type PostApiV2SubscriptionsSubscriptionIdCancelBody = {
  cancellation_reason: string;
};

export type PutApiV2SubscriptionsIdScheduleBody = {
  delivery_day?: string;
  first_delivery?: string;
  frequency?: number;
};

export type PutApiV2ScheduleWeeksIdBody = {
  delivery_date: string;
};

export type GetApiV2Settings200Takeaway = {
  image_banner: string;
};

export type GetApiV2Settings200RecipeKits = {
  image_banner: string;
  one_off_fee: number;
};

export type GetApiV2Settings200 = {
  recipe_kits: GetApiV2Settings200RecipeKits;
  takeaway: GetApiV2Settings200Takeaway;
};

export type PostApiV2Sessions200 = {
  token?: string;
  url?: string;
};

export type PostApiV2SessionsBody = {
  email: string;
  password: string;
  target_url?: string;
};

export type PostApiV2SessionsAdmin200 = {
  token: string;
};

export type PostApiV2SessionsAdminBody = {
  email: string;
  password: string;
};

export type PostApiV2SessionsTokenBody = {
  token: string;
};

export type PostApiV2SessionsGuestTokenBody = {
  token: string;
};

export type PostApiV2SessionsGoogle200 = {
  token?: string;
  url?: string;
};

export type PostApiV2SessionsGoogleBody = {
  access_token?: string;
  target_url?: string;
  token?: string;
};

export type PostApiV2SessionsFacebook200 = {
  token?: string;
  url?: string;
};

export type PostApiV2SessionsFacebookBody = {
  target_url?: string;
  token: string;
};

export type PostApiV2SessionsEmail200 = {
  token?: string;
  url?: string;
};

export type PostApiV2SessionsEmailBody = {
  email: string;
  password: string;
  target_url?: string;
};

export type PostApiV2CheckoutBody = {
  email: string;
  hmac: string;
  shop_name: string;
  shopify_customer_id: string;
  timestamp: string;
};

export type PostApiV2SessionsApple200 = {
  token?: string;
  url?: string;
};

export type PostApiV2SessionsAppleBody = {
  code: string;
  first_name?: string;
  last_name?: string;
  target_url?: string;
};

export type PostApiV2AccountBody = {
  email: string;
  hmac: string;
  shop_name: string;
  shopify_customer_id: string;
  timestamp: string;
};

export type GetApiV2RestaurantsIdTimeSlots200Item = {
  end_time: string;
  start_time: string;
};

export type GetApiV2RestaurantsIdTimeSlotsParams = {
  fulfillment_type: string;
};

export type GetApiV2RestaurantsIdSnoozedProductsParams = {
  fulfillment_type?: string;
};

export type GetApiV2RestaurantsIdMenuParams = {
  fulfillment_type?: string;
};

export type GetApiV2RestaurantsNearestParams = {
  lat?: string;
  lon?: string;
  fulfillment_type?: string;
};

export type PutApiV2RatingsIdBody = {
  channel?: string;
  order_number?: string;
};

export type PostApiV2RatingsBodyItemsAttributesItem = {
  comments?: string;
  feedback?: string[];
  name: string;
  value: number;
};

export type PostApiV2RatingsBody = {
  accepts_emails?: boolean;
  channel?: string;
  comments?: string;
  email: string;
  feedback?: string[];
  first_name: string;
  items_attributes?: PostApiV2RatingsBodyItemsAttributesItem[];
  last_name: string;
  order_number?: string;
  value: number;
};

export type PostApiV2ProductRatings200 = {
  data: ProductRatingObject;
};

export type PostApiV2ProductRatingsBody = {
  line_item_id: string;
  notes?: string;
  rating: number;
  reason?: string;
};

export type GetApiV2OrdersPrintableTicketsParams = {
  'filter[restaurant_id]'?: string;
};

export type PostApiV2PreviewOrderBodyItemsItem = {
  product_variant_id: string;
  quantity: number;
};

export type PostApiV2PreviewOrderBody = {
  items: PostApiV2PreviewOrderBodyItemsItem[];
  schedule_week_id: string;
  voucher_code?: string;
};

export type PostApiV2PaymentsBody = {
  order_id: string;
  payment_method_id?: string;
  payment_method_type?: string;
  payment_processor_name?: string;
};

export type PostApiV2PaymentMethods200 = {
  data: PaymentMethodObject;
};

export type PostApiV2PaymentMethodsBody = {
  payment_processor_name: string;
  setup_intent_id: string;
  type: string;
};

export type GetApiV2PaymentMethods200 = {
  data: PaymentMethodObject[];
};

export type PostApiV2PaymentMethodsSetup200 = {
  setup_intent: string;
};

export type PostApiV2PaymentMethodsSetupBody = {
  payment_processor_name: string;
  type: string;
};

export type PostApiV2PaymentMethodsPaymentMethodIdDefault200 = {
  data: PaymentMethodObject;
};

export type GetApiV2PaymentDetailsPaymentId200 = {
  amount: number;
  client_secret: string;
  delivery_date: string;
  payment_method_id: string;
  /** @nullable */
  status_code: string | null;
};

export type PutApiV2OrdersIdBodyItemsItem = {
  product_variant_id: string;
  quantity: number;
};

export type PutApiV2OrdersIdBody = {
  items: PutApiV2OrdersIdBodyItemsItem[];
};

export type PostApiV2OrdersBodyItemsItem = {
  product_variant_id: string;
  quantity: number;
};

export type PostApiV2OrdersBody = {
  items: PostApiV2OrdersBodyItemsItem[];
  schedule_week_id: string;
};

export type GetApiV2OrdersParams = {
  'filter[delivery_date]'?: string;
  'filter[subscription_id]'?: string;
  'filter[exclude_states]'?: string;
  'filter[type]'?: string;
  page?: string;
  per_page?: string;
};

export type PostApiV2OrdersOrderIdVoucherBody = {
  code: string;
};

export type PostApiV2OrdersTakeawayBody = {
  basket_id: string;
  kitchen_note?: string;
  note?: string;
  payment_method_id?: string;
};

export type PostApiV2OrdersRecipeKitsBody = {
  basket_id: string;
  billing_address_id?: string;
  payment_method_id?: string;
  payment_method_type?: string;
  payment_processor_name?: string;
};

export type PostApiV2OrdersOpenTabCheckoutBody = {
  payment_method_id?: string;
  payment_method_type?: string;
  payment_processor_name?: string;
  restaurant_id: string;
};

export type PostApiV2OrdersOpenTabBody = {
  basket_id: string;
  kitchen_note?: string;
  note?: string;
  payment_method_id?: string;
};

export type GetApiV2OrdersOpenTabParams = {
  restaurant_id?: string;
};

export type PostApiV2OrdersGuestBody = {
  basket_id: string;
  kitchen_note?: string;
  payment_method_type?: string;
  payment_processor_name?: string;
};

export type PostApiV2OrdersOrderIdFeedbackBody = {
  attachment_s3_key?: string;
  delivery_rating?: number;
  hear_about_us?: string;
  hear_about_us_notes?: string;
  notes?: string;
  nps_notes?: string;
  nps_rating?: number;
  reason?: string;
};

export type GetApiV2OrdersOrderIdDriverLocation200 = {
  lat: number;
  lon: number;
};

export type PostApiV2OrdersOrderIdDeliveryDateBody = {
  delivery_date: string;
};

export type PostApiV2OrdersConfirmBody = {
  payment_id: string;
};

export type PutApiV2MeBody = {
  accepts_emails?: boolean;
  accepts_mail?: boolean;
  accepts_sms?: boolean;
  first_name?: string;
  last_name?: string;
};

export type GetApiV2Me200 = {
  customer: Customer;
  mention_me_token: string;
};

export type GetApiV2MarketingOffersParams = {
  /**
 * Restaurant ID. If not given, all marketing offers will be returned.
                              If given, the result will include marketing offers for the given restaurant along with the
                              ones with no restaurant specified.
 */
  restaurant?: string;
  /**
 * Fulfillment type. If not given, all marketing offers will be returned.
                              If given, the result will include marketing offers for the given fulfillment type.
 */
  fulfillment_type?: string;
};

export type PostApiV2SubscriptionsSubscriptionIdIngredientPreferences200 = {
  [key: string]: boolean;
};

export type PostApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody = {
  beef?: boolean;
  eggs?: boolean;
  fish?: boolean;
  milk?: boolean;
  mustard?: boolean;
  nuts?: boolean;
  poultry?: boolean;
};

export type GetApiV2SubscriptionsSubscriptionIdIngredientPreferences200 = {
  [key: string]: boolean;
};

export type PostApiV2ImageUploads200 = {
  key: string;
  url: string;
};

export type PostApiV2ImageUploadsBody = {
  content_type: string;
  file_name: string;
};

export type GetApiV2GoogleReviewsParams = {
  location_id?: string;
  page_token?: string;
};

export type PostApiV2DeliveryDates200DeliveryDatesItem = {
  delivery_date: string;
  /** @nullable */
  original_delivery_date: string | null;
};

export type PostApiV2DeliveryDates200 = {
  delivery_dates: PostApiV2DeliveryDates200DeliveryDatesItem[];
  postcode: string;
};

export type PostApiV2DeliveryDatesBody = {
  delivery_dates: string[];
  postcode: string;
};

export type PostApiV2CustomersBody = {
  accepts_emails: boolean;
  accepts_mail: boolean;
  accepts_sms: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
};

export type PutApiV2CustomersPhoneBody = {
  phone: string;
};

export type PutApiV2CustomersPasswordBody = {
  current_password: string;
  new_password: string;
};

export type PostApiV2CustomersPasswordResetBody = {
  email: string;
};

export type PutApiV2CustomersPaperlessBody = {
  paperless: boolean;
};

export type PostApiV2CustomersFingerprintBody = {
  basket_id?: string;
  fingerprint: string;
};

export type PutApiV2CustomersEmailBody = {
  new_email: string;
  password: string;
};

export type PostApiV2CustomersCreditVoucherBody = {
  code: string;
};

export type PostApiV2CustomersAccountSetupBody = {
  customer_id?: number;
  email?: string;
};

export type PostApiV2CustomerGoogleExperimentsBody = {
  experience_id?: string;
  variant_id: string;
};

export type GetApiV2CourierDeliveryInstructionsParams = {
  postcode?: string;
  delivery_date?: string;
  page?: string;
  per_page?: string;
};

export type PostApiV2ConstraintsBody = {
  begin_date?: string;
  end_date?: string;
  postcode?: string;
  product_variant_ids?: number[];
};

export type GetApiV2ConstraintsParams = {
  begin_date?: string;
  end_date?: string;
  postcode?: string;
};

export type GetApiV2BasketsIdValidate200 = {
  valid: boolean;
};

export type GetApiV2BasketsIdValidateParams = {
  validate_fully?: string;
};

export type PutApiV2BasketsIdBodyFulfillmentType = typeof PutApiV2BasketsIdBodyFulfillmentType[keyof typeof PutApiV2BasketsIdBodyFulfillmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutApiV2BasketsIdBodyFulfillmentType = {
  delivery: 'delivery',
  pickup: 'pickup',
  eat_in: 'eat_in',
} as const;

export type PutApiV2BasketsIdBodyFrequency = typeof PutApiV2BasketsIdBodyFrequency[keyof typeof PutApiV2BasketsIdBodyFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutApiV2BasketsIdBodyFrequency = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type PutApiV2BasketsIdBodyDeliveryDay = typeof PutApiV2BasketsIdBodyDeliveryDay[keyof typeof PutApiV2BasketsIdBodyDeliveryDay];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutApiV2BasketsIdBodyDeliveryDay = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
} as const;

export type PutApiV2BasketsIdBodyAddress = {
  address_provider?: string;
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  lat?: string;
  lon?: string;
  phone?: string;
  region?: string;
  region_code?: string;
  zip?: string;
};

export type PutApiV2BasketsIdBody = {
  address?: PutApiV2BasketsIdBodyAddress;
  customer_email?: string;
  delivery_date?: string;
  delivery_day?: PutApiV2BasketsIdBodyDeliveryDay;
  delivery_note?: string;
  frequency?: PutApiV2BasketsIdBodyFrequency;
  fulfillment_type?: PutApiV2BasketsIdBodyFulfillmentType;
  one_off_fee_applicable?: boolean;
  restaurant_id?: string;
  start_subscription?: boolean;
  table_number?: string;
  time_slot?: string;
  validate_fully?: boolean;
};

export type PostApiV2BasketsBodyOneOfSixType = typeof PostApiV2BasketsBodyOneOfSixType[keyof typeof PostApiV2BasketsBodyOneOfSixType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfSixType = {
  TakeawayBasket: 'TakeawayBasket',
} as const;

export type PostApiV2BasketsBodyOneOfSixTipType = typeof PostApiV2BasketsBodyOneOfSixTipType[keyof typeof PostApiV2BasketsBodyOneOfSixTipType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfSixTipType = {
  none: 'none',
  percentage: 'percentage',
  fixed_amount: 'fixed_amount',
} as const;

export type PostApiV2BasketsBodyOneOfSixFulfillmentType = typeof PostApiV2BasketsBodyOneOfSixFulfillmentType[keyof typeof PostApiV2BasketsBodyOneOfSixFulfillmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfSixFulfillmentType = {
  delivery: 'delivery',
  pickup: 'pickup',
  eat_in: 'eat_in',
} as const;

export type PostApiV2BasketsBodyOneOfSixAddress = {
  address_provider?: string;
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  lat?: string;
  lon?: string;
  phone?: string;
  region?: string;
  region_code?: string;
  zip?: string;
};

export type PostApiV2BasketsBodyOneOfSix = {
  address?: PostApiV2BasketsBodyOneOfSixAddress;
  delivery_date?: string;
  fulfillment_type: PostApiV2BasketsBodyOneOfSixFulfillmentType;
  items?: BasketItemPayload[];
  restaurant_id: string;
  table_number?: string;
  time_slot: string;
  tip_type?: PostApiV2BasketsBodyOneOfSixTipType;
  tip_value?: number;
  type: PostApiV2BasketsBodyOneOfSixType;
};

export type PostApiV2BasketsBodyOneOfType = typeof PostApiV2BasketsBodyOneOfType[keyof typeof PostApiV2BasketsBodyOneOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfType = {
  RecipeKitBasket: 'RecipeKitBasket',
} as const;

export type PostApiV2BasketsBodyOneOfFrequency = typeof PostApiV2BasketsBodyOneOfFrequency[keyof typeof PostApiV2BasketsBodyOneOfFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfFrequency = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

export type PostApiV2BasketsBodyOneOfDeliveryDay = typeof PostApiV2BasketsBodyOneOfDeliveryDay[keyof typeof PostApiV2BasketsBodyOneOfDeliveryDay];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV2BasketsBodyOneOfDeliveryDay = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
} as const;

export type PostApiV2BasketsBodyOneOfAddress = {
  address_provider?: string;
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  lat?: string;
  lon?: string;
  phone?: string;
  region?: string;
  region_code?: string;
  zip?: string;
};

export type PostApiV2BasketsBodyOneOf = {
  address?: PostApiV2BasketsBodyOneOfAddress;
  delivery_date?: string;
  delivery_day?: PostApiV2BasketsBodyOneOfDeliveryDay;
  delivery_note?: string;
  frequency?: PostApiV2BasketsBodyOneOfFrequency;
  items?: BasketItemPayload[];
  one_off_fee_applicable?: boolean;
  start_subscription?: boolean;
  type: PostApiV2BasketsBodyOneOfType;
};

export type PostApiV2BasketsBody = PostApiV2BasketsBodyOneOf | PostApiV2BasketsBodyOneOfSix;

export type GetApiV2BasketsBasketIdVouchersParams = {
  phone?: string;
  fingerprint?: string;
};

export type PostApiV2BasketsBasketIdVouchersBody = {
  code: string;
  fingerprint?: string;
  phone?: string;
  validate?: boolean;
};

export type PostApiV2BasketsBasketIdPreviewOneOffFee200 = {
  total_with_fee: string;
  total_without_fee: string;
};

export type DeleteApiV2BasketsBasketIdItemsManyBody = {
  ids: string[];
};

export type PutApiV2BasketsBasketIdItemsIdBody = {
  quantity: number;
};

export type PostApiV2DeliveryEstimation200 = {
  eta_delay: number;
  /** @nullable */
  max_delivery_time: string | null;
  /** @nullable */
  max_time_to_deliver: number | null;
  /** @nullable */
  min_delivery_time: string | null;
  /** @nullable */
  min_time_to_deliver: number | null;
  prep_time: number;
};

export type PostApiV2DeliveryEstimationBody = {
  address1: string;
  address2?: string;
  city?: string;
  country: string;
  lat: string;
  lon: string;
  number_of_items?: number;
  phone?: string;
  restaurant_id: string;
  time_slot: string;
  zip: string;
};

export type PutApiV2AddressesIdBody = {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  region?: string;
  region_code?: string;
  zip?: string;
};

export type PostApiV2AddressesBody = {
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  country: string;
  country_code?: string;
  first_name: string;
  last_name: string;
  phone?: string;
  region?: string;
  region_code?: string;
  zip: string;
};

export type WeeklyMenuReviewSummaryIncludedItemType = typeof WeeklyMenuReviewSummaryIncludedItemType[keyof typeof WeeklyMenuReviewSummaryIncludedItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WeeklyMenuReviewSummaryIncludedItemType = {
  product_review_summary: 'product_review_summary',
} as const;

export type WeeklyMenuReviewSummaryIncludedItemAttributes = {
  product_id: number;
  rating_average: string;
  rating_count: number;
  /** @nullable */
  shopify_id: string | null;
};

export type WeeklyMenuReviewSummaryIncludedItem = {
  attributes: WeeklyMenuReviewSummaryIncludedItemAttributes;
  id: string;
  type: WeeklyMenuReviewSummaryIncludedItemType;
};

export interface WeeklyMenuReviewSummary {
  data: WeeklyMenuReviewSummaryData;
  included: WeeklyMenuReviewSummaryIncludedItem[];
}

export type WeeklyMenuReviewSummaryDataType = typeof WeeklyMenuReviewSummaryDataType[keyof typeof WeeklyMenuReviewSummaryDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WeeklyMenuReviewSummaryDataType = {
  weekly_menu_review_summary: 'weekly_menu_review_summary',
} as const;

export type WeeklyMenuReviewSummaryDataRelationships = {
  product_review_summaries: RelationshipList;
};

export type WeeklyMenuReviewSummaryDataAttributes = {
  /** @nullable */
  active: boolean | null;
  /** @nullable */
  start_date: string | null;
};

export type WeeklyMenuReviewSummaryData = {
  attributes: WeeklyMenuReviewSummaryDataAttributes;
  id: string;
  relationships: WeeklyMenuReviewSummaryDataRelationships;
  type: WeeklyMenuReviewSummaryDataType;
};

export type VoucherObjectType = typeof VoucherObjectType[keyof typeof VoucherObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VoucherObjectType = {
  voucher: 'voucher',
} as const;

export type VoucherObjectRelationships = {
  product_variants: RelationshipList;
};

export type VoucherObjectAttributes = {
  /** @nullable */
  address_lookup_title: string | null;
  /** @nullable */
  basket_description: string | null;
  basket_types: string[];
  /** @nullable */
  code: string | null;
  /** @nullable */
  end_date: string | null;
  /** @nullable */
  max_items: number | null;
  /** @nullable */
  minimum_spend_per_order: string | null;
  product_variant_ids: string[];
  /** @nullable */
  promo_image_url: string | null;
  /** @nullable */
  short_description: string | null;
  /** @nullable */
  single_order: boolean | null;
  /** @nullable */
  start_date: string | null;
  /** @nullable */
  text_section1: string | null;
  /** @nullable */
  text_section2: string | null;
  /** @nullable */
  text_section3: string | null;
  /** @nullable */
  type: string | null;
  /** @nullable */
  value: string | null;
  /** @nullable */
  value_type: string | null;
};

export interface VoucherObject {
  attributes: VoucherObjectAttributes;
  id: string;
  relationships: VoucherObjectRelationships;
  type: VoucherObjectType;
}

export interface Voucher {
  data: VoucherObject;
}

export interface Versions {
  /** @nullable */
  minimum_admin_app_version: string | null;
  /** @nullable */
  minimum_app_version: string | null;
}

export interface UnavailableProduct {
  dates: string[];
  /** Product id in pensa */
  product: number;
}

export type TakeawayMenuProductObjectType = typeof TakeawayMenuProductObjectType[keyof typeof TakeawayMenuProductObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakeawayMenuProductObjectType = {
  takeaway_menu_product: 'takeaway_menu_product',
} as const;

export type TakeawayMenuProductObjectRelationships = {
  product: Relationship;
};

export interface TakeawayMenuProductObject {
  attributes: TakeawayMenuProductObjectAttributes;
  id: string;
  relationships: TakeawayMenuProductObjectRelationships;
  type: TakeawayMenuProductObjectType;
}

export interface TakeawayMenuProducts {
  data: TakeawayMenuProductObject[];
}

export type TakeawayMenuProductObjectAttributesSubProductsItem = {
  position: number;
  product_id: string;
};

export type TakeawayMenuProductObjectAttributes = {
  /** @nullable */
  description: string | null;
  /** @nullable */
  image_url: string | null;
  /** @nullable */
  max: number | null;
  /** @nullable */
  min: number | null;
  /** @nullable */
  multi_max: number | null;
  /** @nullable */
  multiply: number | null;
  /** @nullable */
  plu: string | null;
  price: string;
  /** @nullable */
  product_type: string | null;
  product_variant_id: string;
  /** @nullable */
  snoozed: boolean | null;
  sub_products: TakeawayMenuProductObjectAttributesSubProductsItem[];
  tags: string[];
  /** @nullable */
  title: string | null;
};

export type TakeawayMenuObjectType = typeof TakeawayMenuObjectType[keyof typeof TakeawayMenuObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakeawayMenuObjectType = {
  takeaway_menu: 'takeaway_menu',
} as const;

export type TakeawayMenuObjectRelationships = {
  categories: RelationshipList;
  products: RelationshipList;
};

export type TakeawayMenuObjectAttributes = {
  /** @nullable */
  description: string | null;
  /** @nullable */
  image_url: string | null;
  /** @nullable */
  menu_type: string | null;
  /** @nullable */
  name: string | null;
};

export interface TakeawayMenuObject {
  attributes: TakeawayMenuObjectAttributes;
  id: string;
  relationships: TakeawayMenuObjectRelationships;
  type: TakeawayMenuObjectType;
}

export type TakeawayMenuCategoryObjectType = typeof TakeawayMenuCategoryObjectType[keyof typeof TakeawayMenuCategoryObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakeawayMenuCategoryObjectType = {
  takeaway_menu_category: 'takeaway_menu_category',
} as const;

export interface TakeawayMenuCategoryObject {
  attributes: TakeawayMenuCategoryObjectAttributes;
  id: string;
  type: TakeawayMenuCategoryObjectType;
}

export type TakeawayMenuCategoryObjectAttributesSubCategoriesItem = {
  position: number;
  takeaway_menu_category_id: number;
};

export type TakeawayMenuCategoryObjectAttributesProductsItem = {
  position: number;
  takeaway_menu_product_id: string;
};

export type TakeawayMenuCategoryObjectAttributes = {
  /** @nullable */
  description: string | null;
  /** @nullable */
  image_url: string | null;
  name: string;
  position: number;
  product_ids: string[];
  products: TakeawayMenuCategoryObjectAttributesProductsItem[];
  sub_categories: TakeawayMenuCategoryObjectAttributesSubCategoriesItem[];
};

export type TakeawayMenuIncludedItem = TakeawayMenuProductObject | TakeawayMenuCategoryObject;

export interface TakeawayMenu {
  data: TakeawayMenuObject;
  included: TakeawayMenuIncludedItem[];
}

export type TakeawayBasketItemObjectType = typeof TakeawayBasketItemObjectType[keyof typeof TakeawayBasketItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TakeawayBasketItemObjectType = {
  takeaway_basket_item: 'takeaway_basket_item',
} as const;

export type TakeawayBasketItemObjectRelationships = {
  parent: Relationship;
  product_variant: Relationship;
  sub_items: RelationshipList;
};

export type TakeawayBasketItemObjectAttributes = {
  discount: string;
  /** @nullable */
  image_url: string | null;
  /** @nullable */
  is_addon: boolean | null;
  /** @nullable */
  multi_max: number | null;
  price: string;
  /** @nullable */
  product_id: string | null;
  quantity: number;
  /** @nullable */
  title: string | null;
  total: string;
  /** @nullable */
  type: string | null;
};

export interface TakeawayBasketItemObject {
  attributes: TakeawayBasketItemObjectAttributes;
  id: string;
  relationships: TakeawayBasketItemObjectRelationships;
  type: TakeawayBasketItemObjectType;
}

export type SubscriptionsIncludedItem =
  | SubscriptionScheduleObject
  | CustomerVoucherObject
  | VoucherObject;

export interface Subscriptions {
  data: SubscriptionObject[];
  included?: SubscriptionsIncludedItem[];
}

export type SubscriptionScheduleWeekObjectType = typeof SubscriptionScheduleWeekObjectType[keyof typeof SubscriptionScheduleWeekObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionScheduleWeekObjectType = {
  subscription_schedule_week: 'subscription_schedule_week',
} as const;

export type SubscriptionScheduleWeekObjectRelationships = {
  order: Relationship;
  subscription: Relationship;
};

export type SubscriptionScheduleWeekObjectAttributes = {
  billing_date: string;
  delivery_date: string;
  /** @nullable */
  original_delivery_date: string | null;
  start_date: string;
  state: string;
};

export interface SubscriptionScheduleWeekObject {
  attributes: SubscriptionScheduleWeekObjectAttributes;
  id: string;
  relationships: SubscriptionScheduleWeekObjectRelationships;
  type: SubscriptionScheduleWeekObjectType;
}

export interface SubscriptionScheduleWeeks {
  data: SubscriptionScheduleWeekObject[];
}

export interface SubscriptionScheduleWeek {
  data: SubscriptionScheduleWeekObject;
}

export type SubscriptionScheduleObjectType = typeof SubscriptionScheduleObjectType[keyof typeof SubscriptionScheduleObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionScheduleObjectType = {
  subscription_schedule: 'subscription_schedule',
} as const;

export type SubscriptionScheduleObjectRelationships = {
  subscription: Relationship;
  weeks: SubscriptionScheduleObjectRelationshipsWeeks;
};

export interface SubscriptionScheduleObject {
  attributes: SubscriptionScheduleObjectAttributes;
  id: string;
  relationships: SubscriptionScheduleObjectRelationships;
  type: SubscriptionScheduleObjectType;
}

export type SubscriptionScheduleObjectRelationshipsWeeksMetaLoyalty = {
  /** @nullable */
  voucher_id: string | null;
  week_ids: string[];
};

export type SubscriptionScheduleObjectRelationshipsWeeksMeta = {
  loyalty: SubscriptionScheduleObjectRelationshipsWeeksMetaLoyalty;
};

export type SubscriptionScheduleObjectRelationshipsWeeksDataItem = {
  id: string;
  type: string;
};

export type SubscriptionScheduleObjectRelationshipsWeeks = {
  data: SubscriptionScheduleObjectRelationshipsWeeksDataItem[];
  meta: SubscriptionScheduleObjectRelationshipsWeeksMeta;
};

export type SubscriptionScheduleObjectAttributes = {
  /** @nullable */
  delivery_day: string | null;
  /** @nullable */
  first_delivery: string | null;
  /** @nullable */
  frequency: number | null;
};

export interface SubscriptionSchedule {
  data: SubscriptionScheduleObject;
}

export type SubscriptionObjectType = typeof SubscriptionObjectType[keyof typeof SubscriptionObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionObjectType = {
  subscription: 'subscription',
} as const;

export type SubscriptionObjectRelationships = {
  billing_address: Relationship;
  customer: Relationship;
  customer_vouchers: RelationshipList;
  payment_method: Relationship;
  schedule: Relationship;
  shipping_address: Relationship;
};

export type SubscriptionObjectAttributes = {
  /** @nullable */
  beef: boolean | null;
  /** @nullable */
  cocktails: boolean | null;
  /** @nullable */
  crustaceans: boolean | null;
  /** @nullable */
  delivery_note: string | null;
  /** @nullable */
  double_portion: boolean | null;
  /** @nullable */
  ext_id: string | null;
  /** @nullable */
  fish: boolean | null;
  /** @nullable */
  game: boolean | null;
  /** @nullable */
  gluten_free: boolean | null;
  /** @nullable */
  lamb: boolean | null;
  /** @nullable */
  molluscs: boolean | null;
  /** @nullable */
  name: string | null;
  /** @nullable */
  number_of_cocktails: number | null;
  /** @nullable */
  number_of_recipes: number | null;
  /** @nullable */
  nuts: boolean | null;
  /** @nullable */
  pork: boolean | null;
  /** @nullable */
  recipe_variety: boolean | null;
  /** @nullable */
  state: string | null;
  /** @nullable */
  vegan: boolean | null;
  /** @nullable */
  vegetarian: boolean | null;
};

export interface SubscriptionObject {
  attributes: SubscriptionObjectAttributes;
  id: string;
  relationships: SubscriptionObjectRelationships;
  type: SubscriptionObjectType;
}

export type SubscriptionIncludedItem =
  | SubscriptionScheduleObject
  | SubscriptionScheduleWeekObject
  | CustomerVoucherObject
  | CustomerObject
  | AddressObject
  | PaymentMethodObject
  | VoucherObject;

export interface Subscription {
  data: SubscriptionObject;
  included?: SubscriptionIncludedItem[];
}

export interface StoreCreditTransactions {
  data: StoreCreditTransactionsDataItem[];
}

export type StoreCreditTransactionsDataItemType = typeof StoreCreditTransactionsDataItemType[keyof typeof StoreCreditTransactionsDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreCreditTransactionsDataItemType = {
  store_credit_transaction: 'store_credit_transaction',
} as const;

/**
 * @nullable
 */
export type StoreCreditTransactionsDataItemAttributesState =
  | typeof StoreCreditTransactionsDataItemAttributesState[keyof typeof StoreCreditTransactionsDataItemAttributesState]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreCreditTransactionsDataItemAttributesState = {
  available: 'available',
  spent: 'spent',
  expiring: 'expiring',
  expired: 'expired',
} as const;

export type StoreCreditTransactionsDataItemAttributes = {
  amount: string;
  created_at: string;
  /** @nullable */
  description: string | null;
  /** @nullable */
  expired_at: string | null;
  /** @nullable */
  expiry_date: string | null;
  /** @nullable */
  source: string | null;
  /** @nullable */
  state: StoreCreditTransactionsDataItemAttributesState;
};

export type StoreCreditTransactionsDataItem = {
  attributes: StoreCreditTransactionsDataItemAttributes;
  id: string;
  type: StoreCreditTransactionsDataItemType;
};

export type ShipmentObjectType = typeof ShipmentObjectType[keyof typeof ShipmentObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentObjectType = {
  shipment: 'shipment',
} as const;

export type ShipmentObjectRelationships = {
  items: RelationshipList;
};

export type ShipmentObjectAttributes = {
  /** @nullable */
  description: string | null;
  discount: string;
  /** @nullable */
  dropoff_eta: string | null;
  /** @nullable */
  estimated_delivery_time: string | null;
  /** @nullable */
  pickup_eta: string | null;
  price: string;
  /** @nullable */
  state: string | null;
  /** @nullable */
  threshold: string | null;
  total: string;
  /** @nullable */
  tracking_url: string | null;
};

export interface ShipmentObject {
  attributes: ShipmentObjectAttributes;
  id: string;
  relationships: ShipmentObjectRelationships;
  type: ShipmentObjectType;
}

export type ShipmentItemObjectType = typeof ShipmentItemObjectType[keyof typeof ShipmentItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentItemObjectType = {
  shipment_item: 'shipment_item',
} as const;

export type ShipmentItemObjectAttributes = {
  /** @nullable */
  description: string | null;
  price: string;
  /** @nullable */
  root: boolean | null;
  /** @nullable */
  threshold: string | null;
};

export interface ShipmentItemObject {
  attributes: ShipmentItemObjectAttributes;
  id: string;
  type: ShipmentItemObjectType;
}

export type RewardBlocksDataItemType = typeof RewardBlocksDataItemType[keyof typeof RewardBlocksDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RewardBlocksDataItemType = {
  reward_block: 'reward_block',
} as const;

export type RewardBlocksDataItemAttributes = {
  /** @nullable */
  active: boolean | null;
  /** @nullable */
  category: string | null;
  /** @nullable */
  image_url: string | null;
  /** @nullable */
  link_url: string | null;
  /** @nullable */
  name: string | null;
  /** @nullable */
  position: number | null;
};

export type RewardBlocksDataItem = {
  attributes: RewardBlocksDataItemAttributes;
  id: string;
  type: RewardBlocksDataItemType;
};

export interface RewardBlocks {
  data: RewardBlocksDataItem[];
  meta: PagyMeta;
}

export type RestaurantObjectType = typeof RestaurantObjectType[keyof typeof RestaurantObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestaurantObjectType = {
  restaurant: 'restaurant',
} as const;

export interface RestaurantObject {
  attributes: RestaurantObjectAttributes;
  id: string;
  type: RestaurantObjectType;
}

export interface Restaurants {
  data: RestaurantObject[];
}

export type RestaurantObjectAttributesTableSectionsItemTableNumbersItem = {
  id: string;
  position: number;
  value: string;
};

export type RestaurantObjectAttributesTableSectionsItem = {
  id: string;
  name: string;
  position: number;
  table_numbers: RestaurantObjectAttributesTableSectionsItemTableNumbersItem[];
  table_numbers_count: number;
};

export type RestaurantObjectAttributes = {
  /** @nullable */
  address1: string | null;
  /** @nullable */
  address2: string | null;
  /** @nullable */
  city: string | null;
  eat_in: boolean;
  /** @nullable */
  franchisee_id: string | null;
  has_table_numbers: boolean;
  /** @nullable */
  lat: string | null;
  /** @nullable */
  lon: string | null;
  /** @nullable */
  number_of_tables: number | null;
  /** @nullable */
  phone: string | null;
  pickup: boolean;
  /** @nullable */
  postcode: string | null;
  same_day_delivery: boolean;
  /** @nullable */
  site: string | null;
  /** @nullable */
  site_slug: string | null;
  table_sections: RestaurantObjectAttributesTableSectionsItem[];
};

export interface Restaurant {
  data: RestaurantObject;
}

export type RelationshipListDataItem = {
  id: string;
  type: string;
};

export interface RelationshipList {
  data: RelationshipListDataItem[];
}

/**
 * @nullable
 */
export type RelationshipData = {
  id: string;
  type: string;
} | null;

export interface Relationship {
  /** @nullable */
  data: RelationshipData;
}

export type RefundObjectType = typeof RefundObjectType[keyof typeof RefundObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefundObjectType = {
  refund: 'refund',
} as const;

export type RefundObjectRelationships = {
  order: Relationship;
  order_payment: Relationship;
};

export type RefundObjectAttributes = {
  /** @nullable */
  amount: string | null;
  /** @nullable */
  processor_id: string | null;
  /** @nullable */
  state: string | null;
};

export interface RefundObject {
  attributes: RefundObjectAttributes;
  id: string;
  relationships: RefundObjectRelationships;
  type: RefundObjectType;
}

export type RefundLineItemObjectType = typeof RefundLineItemObjectType[keyof typeof RefundLineItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefundLineItemObjectType = {
  refund_line_item: 'refund_line_item',
} as const;

export type RefundLineItemObjectRelationships = {
  line_item: Relationship;
  refund: Relationship;
};

export type RefundLineItemObjectAttributes = {
  /** @nullable */
  quantity: number | null;
};

export interface RefundLineItemObject {
  attributes: RefundLineItemObjectAttributes;
  id: string;
  relationships: RefundLineItemObjectRelationships;
  type: RefundLineItemObjectType;
}

export interface Rating {
  data: RatingData;
}

export type RatingDataType = typeof RatingDataType[keyof typeof RatingDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RatingDataType = {
  rating: 'rating',
} as const;

export type RatingDataAttributesItemsItem = {
  /** @nullable */
  comments: string | null;
  feedback: (string | null)[];
  /** @nullable */
  name: string | null;
  /** @nullable */
  value: number | null;
};

export type RatingDataAttributes = {
  /** @nullable */
  channel: string | null;
  /** @nullable */
  comments: string | null;
  /** @nullable */
  email: string | null;
  /** @nullable */
  feedback: (string | null)[] | null;
  /** @nullable */
  first_name: string | null;
  items: RatingDataAttributesItemsItem[];
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  order_number: string | null;
  /** @nullable */
  value: number | null;
};

export type RatingData = {
  attributes: RatingDataAttributes;
  id: string;
  type: RatingDataType;
};

export type ProductVariantObjectType = typeof ProductVariantObjectType[keyof typeof ProductVariantObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductVariantObjectType = {
  product_variant: 'product_variant',
} as const;

export type ProductVariantObjectRelationships = {
  product: Relationship;
};

export type ProductVariantObjectAttributes = {
  /** @nullable */
  available_for_sale: boolean | null;
  /** @nullable */
  compare_at_price: string | null;
  /** @nullable */
  ext_id: string | null;
  /** @nullable */
  inventory_quantity: number | null;
  /** @nullable */
  is_double: boolean | null;
  /** @nullable */
  plu: string | null;
  /** @nullable */
  price: string | null;
  /** @nullable */
  sku: string | null;
  /** @nullable */
  title: string | null;
  /** @nullable */
  type: string | null;
};

export interface ProductVariantObject {
  attributes: ProductVariantObjectAttributes;
  id: string;
  relationships: ProductVariantObjectRelationships;
  type: ProductVariantObjectType;
}

export type ProductRatingObjectType = typeof ProductRatingObjectType[keyof typeof ProductRatingObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductRatingObjectType = {
  product_rating: 'product_rating',
} as const;

export type ProductRatingObjectRelationships = {
  customer: Relationship;
  line_item: Relationship;
  product: Relationship;
};

export type ProductRatingObjectAttributes = {
  /** @nullable */
  notes: string | null;
  rating: number;
};

export interface ProductRatingObject {
  attributes: ProductRatingObjectAttributes;
  id: string;
  relationships: ProductRatingObjectRelationships;
  type: ProductRatingObjectType;
}

export type PrintableOrdersDataItemType = typeof PrintableOrdersDataItemType[keyof typeof PrintableOrdersDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrintableOrdersDataItemType = {
  order: 'order',
} as const;

export type PrintableOrdersDataItem = {
  attributes: PrintableOrdersDataItemAttributes;
  id: string;
  type: PrintableOrdersDataItemType;
};

export interface PrintableOrders {
  data: PrintableOrdersDataItem[];
}

export type PrintableOrdersDataItemAttributesTotalLineItemsPrice = {
  /** @nullable */
  content: string | null;
  /** @nullable */
  label: string | null;
  /** @nullable */
  value: string | null;
};

export type PrintableOrdersDataItemAttributesTotal = {
  /** @nullable */
  content: string | null;
  /** @nullable */
  label: string | null;
  /** @nullable */
  value: string | null;
};

export type PrintableOrdersDataItemAttributesSubtotalPrice = {
  /** @nullable */
  content: string | null;
  /** @nullable */
  label: string | null;
  /** @nullable */
  value: string | null;
};

export type PrintableOrdersDataItemAttributesShipment = {
  /** @nullable */
  state: string | null;
};

export type PrintableOrdersDataItemAttributesFormattedCreatedAt = {
  date: string;
  time: string;
};

export type PrintableOrdersDataItemAttributesDiscountTotal = {
  /** @nullable */
  content: string | null;
  /** @nullable */
  label: string | null;
  /** @nullable */
  value: string | null;
};

export type PrintableOrdersDataItemAttributesDeliveryAddress = {
  /** @nullable */
  address1: string | null;
  /** @nullable */
  address2: string | null;
  /** @nullable */
  city: string | null;
  /** @nullable */
  company: string | null;
  /** @nullable */
  country: string | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  lat: string | null;
  /** @nullable */
  lon: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  zip: string | null;
};

export type PrintableOrdersDataItemAttributesCustomer = {
  /** @nullable */
  full_name: string | null;
  /** @nullable */
  phone: string | null;
};

export interface PrintableOrderItem {
  id: string;
  price: string;
  quantity: number;
  sub_items: PrintableOrderItem[];
  /** @nullable */
  title: string | null;
}

export type PrintableOrdersDataItemAttributes = {
  /** @nullable */
  created_at: string | null;
  customer: PrintableOrdersDataItemAttributesCustomer;
  delivery_address: PrintableOrdersDataItemAttributesDeliveryAddress;
  discount_total: PrintableOrdersDataItemAttributesDiscountTotal;
  /** @nullable */
  estimated_pickup_time: string | null;
  formatted_created_at: PrintableOrdersDataItemAttributesFormattedCreatedAt;
  /** @nullable */
  fulfillment_type: string | null;
  /** @nullable */
  hid: string | null;
  /** @nullable */
  kitchen_note: string | null;
  /** @nullable */
  last_ticket_at: string | null;
  line_items: PrintableOrderItem[];
  shipment: PrintableOrdersDataItemAttributesShipment;
  /** @nullable */
  short_id: number | null;
  /** @nullable */
  state: string | null;
  subtotal_price: PrintableOrdersDataItemAttributesSubtotalPrice;
  /** @nullable */
  ticket_count: number | null;
  /** @nullable */
  time_slot: string | null;
  total: PrintableOrdersDataItemAttributesTotal;
  total_line_items_price: PrintableOrdersDataItemAttributesTotalLineItemsPrice;
};

export type PaypalAccountAttributesType = typeof PaypalAccountAttributesType[keyof typeof PaypalAccountAttributesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaypalAccountAttributesType = {
  PaypalAccount: 'PaypalAccount',
} as const;

export interface PaypalAccountAttributes {
  /** @nullable */
  default: boolean | null;
  /** @nullable */
  payer_email: string | null;
  /** @nullable */
  payer_id: string | null;
  /** @nullable */
  processor_id: string | null;
  type: PaypalAccountAttributesType;
}

export type PaymentProcessorObjectType = typeof PaymentProcessorObjectType[keyof typeof PaymentProcessorObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentProcessorObjectType = {
  payment_processor: 'payment_processor',
} as const;

export type PaymentProcessorObjectAttributes = {
  name: string;
};

export interface PaymentProcessorObject {
  attributes: PaymentProcessorObjectAttributes;
  id: string;
  type: PaymentProcessorObjectType;
}

export type PaymentObjectType = typeof PaymentObjectType[keyof typeof PaymentObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentObjectType = {
  payment: 'payment',
} as const;

export type PaymentObjectRelationships = {
  payment_method: Relationship;
  payment_processor: Relationship;
};

export type PaymentObjectAttributes = {
  /** @nullable */
  amount: string | null;
  /** @nullable */
  reference: string | null;
  /** @nullable */
  state: string | null;
  /** @nullable */
  status_code: string | null;
  /** @nullable */
  store_credits: boolean | null;
};

export interface PaymentObject {
  attributes: PaymentObjectAttributes;
  id: string;
  relationships: PaymentObjectRelationships;
  type: PaymentObjectType;
}

export type PaymentMethodObjectType = typeof PaymentMethodObjectType[keyof typeof PaymentMethodObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethodObjectType = {
  payment_method: 'payment_method',
} as const;

export type PaymentMethodObjectRelationships = {
  customer: Relationship;
  payment_processor: Relationship;
};

export type PaymentMethodObjectAttributes = CreditCardAttributes | PaypalAccountAttributes;

export interface PaymentMethodObject {
  attributes: PaymentMethodObjectAttributes;
  id: string;
  relationships: PaymentMethodObjectRelationships;
  type: PaymentMethodObjectType;
}

export type PaymentInfoPayment = {
  data: PaymentObject;
};

export interface PaymentInfo {
  payment: PaymentInfoPayment;
  payment_intent_client_secret?: string;
}

export type PaymentIncludedItem = PaymentMethodObject | PaymentProcessorObject;

export interface Payment {
  data: PaymentObject;
  included?: PaymentIncludedItem[];
}

export interface PagyMeta {
  current_page: number;
  last_page: number;
  /** @nullable */
  next_page: number | null;
  per_page: number;
  /** @nullable */
  prev_page: number | null;
  total_count: number;
}

export type OrdersIncludedItem =
  | LineItemObject
  | OrderAddressObject
  | CustomerVoucherObject
  | VoucherObject
  | OrderFeedbackObject
  | ShipmentObject
  | ShipmentItemObject
  | RestaurantObject;

export interface Orders {
  data: OrderObject[];
  included?: OrdersIncludedItem[];
  meta?: PagyMeta;
}

export type OrderTicketDataType = typeof OrderTicketDataType[keyof typeof OrderTicketDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTicketDataType = {
  order: 'order',
} as const;

export type OrderTicketDataAttributes = {
  hid: string;
  last_ticket_at: string;
  state: string;
  ticket_count: number;
};

export type OrderTicketData = {
  attributes: OrderTicketDataAttributes;
  id: string;
  type: OrderTicketDataType;
};

export interface OrderTicket {
  data: OrderTicketData;
}

export type OrderPaymentObjectType = typeof OrderPaymentObjectType[keyof typeof OrderPaymentObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderPaymentObjectType = {
  order_payment: 'order_payment',
} as const;

export type OrderPaymentObjectRelationships = {
  payment: Relationship;
};

export type OrderPaymentObjectAttributes = {
  /** @nullable */
  amount: string | null;
};

export interface OrderPaymentObject {
  attributes: OrderPaymentObjectAttributes;
  id: string;
  relationships: OrderPaymentObjectRelationships;
  type: OrderPaymentObjectType;
}

export type OrderObjectType = typeof OrderObjectType[keyof typeof OrderObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderObjectType = {
  order: 'order',
} as const;

export type OrderObjectRelationships = {
  customer: Relationship;
  customer_voucher: Relationship;
  line_items: RelationshipList;
  order_billing_address: Relationship;
  order_feedback: Relationship;
  order_payments: RelationshipList;
  order_shipping_address: Relationship;
  refunds: RelationshipList;
  restaurant?: Relationship;
  shipment: Relationship;
  subscription: Relationship;
};

export interface OrderObject {
  attributes: OrderObjectAttributes;
  id: string;
  relationships: OrderObjectRelationships;
  type: OrderObjectType;
}

export type OrderObjectAttributesType = typeof OrderObjectAttributesType[keyof typeof OrderObjectAttributesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderObjectAttributesType = {
  DeliverectOrder: 'DeliverectOrder',
  ShopifyOrder: 'ShopifyOrder',
} as const;

/**
 * @nullable
 */
export type OrderObjectAttributesFulfillmentType =
  | typeof OrderObjectAttributesFulfillmentType[keyof typeof OrderObjectAttributesFulfillmentType]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderObjectAttributesFulfillmentType = {
  delivery: 'delivery',
  pickup: 'pickup',
  eat_in: 'eat_in',
} as const;

export type OrderObjectAttributes = {
  /** @nullable */
  copy_source_id?: string | null;
  /** @nullable */
  copy_target_id?: string | null;
  /** @nullable */
  created_at: string | null;
  /** @nullable */
  delivery_date: string | null;
  /** @nullable */
  ext_id: string | null;
  /** @nullable */
  ext_state: string | null;
  /** @nullable */
  ext_state_at: string | null;
  /** @nullable */
  ext_state_reason: string | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  fulfillment_type: OrderObjectAttributesFulfillmentType;
  /** @nullable */
  hid: string | null;
  /** @nullable */
  kitchen_note: string | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  name: string | null;
  /** @nullable */
  note: string | null;
  /** @nullable */
  open_tab: boolean | null;
  /** @nullable */
  order_number: number | null;
  /** @nullable */
  original_delivery_date: string | null;
  /** @nullable */
  payment_method_id: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  short_id: number | null;
  /** @nullable */
  source: string | null;
  /** @nullable */
  state: string | null;
  /** @nullable */
  subscription_id: number | null;
  subtotal_price: string;
  /** @nullable */
  table_number: string | null;
  tags: string[];
  /** @nullable */
  time_slot: string | null;
  /** @nullable */
  time_slot_end_time: string | null;
  total_discounts: string;
  total_line_items_price: string;
  total_price: string;
  total_refunds: string;
  /** @nullable */
  tracking_url: string | null;
  type: OrderObjectAttributesType;
};

export type OrderFeedbackObjectType = typeof OrderFeedbackObjectType[keyof typeof OrderFeedbackObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderFeedbackObjectType = {
  order_feedback: 'order_feedback',
} as const;

export type OrderFeedbackObjectRelationships = {
  order: Relationship;
};

export type OrderFeedbackObjectAttributes = {
  /** @nullable */
  hear_about_us: string | null;
  /** @nullable */
  hear_about_us_notes: string | null;
  /** @nullable */
  notes: string | null;
  /** @nullable */
  nps_notes: string | null;
  /** @nullable */
  nps_rating: number | null;
  /** @nullable */
  reason: string | null;
};

export interface OrderFeedbackObject {
  attributes: OrderFeedbackObjectAttributes;
  id: string;
  relationships: OrderFeedbackObjectRelationships;
  type: OrderFeedbackObjectType;
}

export interface OrderFeedback {
  data: OrderFeedbackObject;
}

export interface OrderCreationResponse {
  order_id: string;
  payment: Payment;
  payment_intent_client_secret?: string;
  /** @nullable */
  subscription?: Subscription;
}

export type OrderAddressObjectType = typeof OrderAddressObjectType[keyof typeof OrderAddressObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderAddressObjectType = {
  order_address: 'order_address',
} as const;

export type OrderAddressObjectAttributes = {
  /** @nullable */
  address1: string | null;
  /** @nullable */
  address2: string | null;
  /** @nullable */
  city: string | null;
  /** @nullable */
  company: string | null;
  /** @nullable */
  country: string | null;
  /** @nullable */
  country_code: string | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  lat: string | null;
  /** @nullable */
  lon: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  region: string | null;
  /** @nullable */
  region_code: string | null;
  /** @nullable */
  zip: string | null;
};

export interface OrderAddressObject {
  attributes: OrderAddressObjectAttributes;
  id: string;
  type: OrderAddressObjectType;
}

export type OrderIncludedItem =
  | LineItemObject
  | RefundLineItemObject
  | OrderPaymentObject
  | PaymentObject
  | OrderAddressObject
  | PaymentMethodObject
  | RefundObject
  | CustomerVoucherObject
  | VoucherObject
  | OrderFeedbackObject
  | ShipmentObject
  | ShipmentItemObject
  | RestaurantObject;

export interface Order {
  data: OrderObject;
  included?: OrderIncludedItem[];
}

export type OpenTabObjectType = typeof OpenTabObjectType[keyof typeof OpenTabObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpenTabObjectType = {
  open_tab: 'open_tab',
} as const;

export type OpenTabObjectAttributes = {
  customer_id: number;
  /** @nullable */
  order_id: string | null;
  restaurant_id: number;
  state: string;
};

export interface OpenTabObject {
  attributes: OpenTabObjectAttributes;
  id: string;
  type: OpenTabObjectType;
}

export interface OpenTab {
  data: OpenTabObject;
}

export type MarketingOffersDataItemType = typeof MarketingOffersDataItemType[keyof typeof MarketingOffersDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketingOffersDataItemType = {
  marketing_offer: 'marketing_offer',
} as const;

export type MarketingOffersDataItemAttributes = {
  active: boolean;
  category: string;
  customer_type: string;
  position: number;
  restaurants: number[];
  terms_and_conditions: string;
  text: string;
  /** @nullable */
  variant: string | null;
  voucher_code: string;
};

export type MarketingOffersDataItem = {
  attributes: MarketingOffersDataItemAttributes;
  id: string;
  type: MarketingOffersDataItemType;
};

export interface MarketingOffers {
  data: MarketingOffersDataItem[];
  meta: PagyMeta;
}

export type MarketingBannersDataItemType = typeof MarketingBannersDataItemType[keyof typeof MarketingBannersDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketingBannersDataItemType = {
  marketing_banner: 'marketing_banner',
} as const;

export type MarketingBannersDataItemAttributes = {
  /** @nullable */
  active: boolean | null;
  /** @nullable */
  bg_color: string | null;
  /** @nullable */
  category: string | null;
  /** @nullable */
  customer_type: string | null;
  /** @nullable */
  fg_color: string | null;
  /** @nullable */
  link: string | null;
  /** @nullable */
  mobile_text: string | null;
  /** @nullable */
  position: number | null;
  /** @nullable */
  text: string | null;
};

export type MarketingBannersDataItem = {
  attributes: MarketingBannersDataItemAttributes;
  id: string;
  type: MarketingBannersDataItemType;
};

export interface MarketingBanners {
  data: MarketingBannersDataItem[];
  meta: PagyMeta;
}

export type LineItemObjectType = typeof LineItemObjectType[keyof typeof LineItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LineItemObjectType = {
  line_item: 'line_item',
} as const;

export type LineItemObjectRelationships = {
  order: Relationship;
  product: Relationship;
  product_rating: Relationship;
  refund_line_items: RelationshipList;
  sub_items: RelationshipList;
  variant: Relationship;
};

export type LineItemObjectAttributes = {
  discount: string;
  /** @nullable */
  image_url: string | null;
  is_addon: boolean;
  /** @nullable */
  name: string | null;
  /** @nullable */
  parent_line_item: string | null;
  price: string;
  /** @nullable */
  product_id: number | null;
  /** @nullable */
  product_variant_ext_id: string | null;
  /** @nullable */
  product_variant_id: number | null;
  quantity: number;
  /** @nullable */
  sku: string | null;
  tags: string[];
  /** @nullable */
  title: string | null;
  total: string;
  /** @nullable */
  variant_title: string | null;
};

export interface LineItemObject {
  attributes: LineItemObjectAttributes;
  id: string;
  relationships: LineItemObjectRelationships;
  type: LineItemObjectType;
}

export interface Ingredients {
  data: IngredientsDataItem[];
  meta: PagyMeta;
}

export type IngredientsDataItemType = typeof IngredientsDataItemType[keyof typeof IngredientsDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngredientsDataItemType = {
  ingredient: 'ingredient',
} as const;

export type IngredientsDataItemAttributes = {
  /** @nullable */
  allergen: boolean | null;
  /** @nullable */
  name: string | null;
  /** @nullable */
  note: string | null;
  /** @nullable */
  position: number | null;
  /** @nullable */
  tag: string | null;
  /** @nullable */
  vegan: boolean | null;
  /** @nullable */
  vegetarian: boolean | null;
};

export type IngredientsDataItem = {
  attributes: IngredientsDataItemAttributes;
  id: string;
  type: IngredientsDataItemType;
};

export type GoogleReviewsReviewsItemReviewReply = {
  comment?: string;
  updateTime?: string;
};

export type GoogleReviewsReviewsItemReviewer = {
  displayName: string;
  isAnonymous?: boolean;
  profilePhotoUrl: string;
};

export type GoogleReviewsReviewsItem = {
  comment?: string;
  createTime: string;
  name: string;
  reviewer: GoogleReviewsReviewsItemReviewer;
  reviewId: string;
  reviewReply?: GoogleReviewsReviewsItemReviewReply;
  starRating: string;
  updateTime: string;
};

export interface GoogleReviews {
  averageRating: number;
  nextPageToken: string;
  reviews: GoogleReviewsReviewsItem[];
  totalReviewCount: number;
}

export interface EmptyObject {
  [key: string]: unknown;
}

export type DeliveryFeeConfigItemObjectType = typeof DeliveryFeeConfigItemObjectType[keyof typeof DeliveryFeeConfigItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryFeeConfigItemObjectType = {
  delivery_fee_config_item: 'delivery_fee_config_item',
} as const;

export interface DeliveryFeeConfigItemObject {
  attributes: DeliveryFeeConfigItemObjectAttributes;
  id: string;
  type: DeliveryFeeConfigItemObjectType;
}

export type DeliveryFeeConfigItemObjectAttributesWeekDay = {
  /** @nullable */
  index: number | null;
  /** @nullable */
  name: string | null;
};

export type DeliveryFeeConfigItemObjectAttributes = {
  /** @nullable */
  name: string | null;
  price: string;
  /** @nullable */
  threshold: string | null;
  week_day: DeliveryFeeConfigItemObjectAttributesWeekDay;
};

export interface DeliveryFeeConfig {
  data: DeliveryFeeConfigData;
  included: DeliveryFeeConfigItemObject[];
}

export type DeliveryFeeConfigDataType = typeof DeliveryFeeConfigDataType[keyof typeof DeliveryFeeConfigDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryFeeConfigDataType = {
  delivery_fee_config: 'delivery_fee_config',
} as const;

export type DeliveryFeeConfigDataRelationships = {
  children: RelationshipList;
};

export type DeliveryFeeConfigDataAttributes = {
  /** @nullable */
  name: string | null;
  price: string;
  /** @nullable */
  threshold: string | null;
};

export type DeliveryFeeConfigData = {
  attributes: DeliveryFeeConfigDataAttributes;
  id: string;
  relationships: DeliveryFeeConfigDataRelationships;
  type: DeliveryFeeConfigDataType;
};

export type CustomerVoucherObjectType = typeof CustomerVoucherObjectType[keyof typeof CustomerVoucherObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerVoucherObjectType = {
  customer_voucher: 'customer_voucher',
} as const;

export type CustomerVoucherObjectRelationships = {
  customer: Relationship;
  orders: RelationshipList;
  subscription: Relationship;
  voucher: Relationship;
};

export type CustomerVoucherObjectAttributes = {
  /** @nullable */
  remaining: number | null;
  /** @nullable */
  usage_count: number | null;
};

export interface CustomerVoucherObject {
  attributes: CustomerVoucherObjectAttributes;
  id: string;
  relationships: CustomerVoucherObjectRelationships;
  type: CustomerVoucherObjectType;
}

export type CustomerObjectType = typeof CustomerObjectType[keyof typeof CustomerObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerObjectType = {
  customer: 'customer',
} as const;

export type CustomerObjectAttributes = {
  /** @nullable */
  accepts_emails: boolean | null;
  /** @nullable */
  accepts_mail: boolean | null;
  /** @nullable */
  accepts_sms: boolean | null;
  email: string;
  /** @nullable */
  ext_id: number | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  has_order_with_voucher: boolean | null;
  /** @nullable */
  has_shopify_orders: boolean | null;
  /** @nullable */
  has_subscription_orders: boolean | null;
  /** @nullable */
  has_takeaway_orders: boolean | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  social_login: string | null;
  /** @nullable */
  store_credits: string | null;
  tags: string[];
};

export interface CustomerObject {
  attributes: CustomerObjectAttributes;
  id: string;
  type: CustomerObjectType;
}

export interface Customer {
  data: CustomerObject;
}

export type CreditCardAttributesType = typeof CreditCardAttributesType[keyof typeof CreditCardAttributesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreditCardAttributesType = {
  CreditCard: 'CreditCard',
} as const;

export interface CreditCardAttributes {
  /** @nullable */
  brand: string | null;
  /** @nullable */
  default: boolean | null;
  /** @nullable */
  exp_month: number | null;
  /** @nullable */
  exp_year: number | null;
  /** @nullable */
  last4: string | null;
  /** @nullable */
  processor_id: string | null;
  type: CreditCardAttributesType;
}

export type CourierDeliveryInstructionsDataItemType = typeof CourierDeliveryInstructionsDataItemType[keyof typeof CourierDeliveryInstructionsDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourierDeliveryInstructionsDataItemType = {
  courier_delivery_instruction: 'courier_delivery_instruction',
} as const;

export type CourierDeliveryInstructionsDataItemAttributes = {
  /** @nullable */
  active: boolean | null;
  /** @nullable */
  courier_id: string | null;
  /** @nullable */
  instruction: string | null;
  /** @nullable */
  position: number | null;
};

export type CourierDeliveryInstructionsDataItem = {
  attributes: CourierDeliveryInstructionsDataItemAttributes;
  id: string;
  type: CourierDeliveryInstructionsDataItemType;
};

export interface CourierDeliveryInstructions {
  data: CourierDeliveryInstructionsDataItem[];
  meta: PagyMeta;
}

export interface ConstrainedDates {
  unavailable_dates: string[];
  unavailable_products: UnavailableProduct[];
}

export type BundlesDataItemType = typeof BundlesDataItemType[keyof typeof BundlesDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BundlesDataItemType = {
  bundle: 'bundle',
} as const;

export type BundlesDataItemAttributes = {
  bundle_variants: BundleVariant[];
  /** @nullable */
  product_ext_id: string | null;
  /** @nullable */
  product_id: number | null;
  /** @nullable */
  product_variant_ext_id: string | null;
  /** @nullable */
  product_variant_id: number | null;
};

export type BundlesDataItem = {
  attributes: BundlesDataItemAttributes;
  id: string;
  type: BundlesDataItemType;
};

export interface Bundles {
  data: BundlesDataItem[];
  meta: PagyMeta;
}

export type BundleVariantDataType = typeof BundleVariantDataType[keyof typeof BundleVariantDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BundleVariantDataType = {
  bundle_variant: 'bundle_variant',
} as const;

export type BundleVariantDataAttributes = {
  /** @nullable */
  product_ext_id: string | null;
  /** @nullable */
  product_id: number | null;
  /** @nullable */
  product_variant_ext_id: string | null;
  /** @nullable */
  product_variant_id: number | null;
  /** @nullable */
  quantity: number | null;
};

export type BundleVariantData = {
  attributes: BundleVariantDataAttributes;
  id: string;
  type: BundleVariantDataType;
};

export interface BundleVariant {
  data: BundleVariantData;
}

export interface Baskets {
  data: BasketObject[];
}

export type BasketShipmentObjectType = typeof BasketShipmentObjectType[keyof typeof BasketShipmentObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketShipmentObjectType = {
  basket_shipment: 'basket_shipment',
} as const;

export type BasketShipmentObjectRelationships = {
  items: RelationshipList;
};

export type BasketShipmentObjectAttributes = {
  /** @nullable */
  description: string | null;
  discount: string;
  price: string;
  /** @nullable */
  threshold: string | null;
  total: string;
};

export interface BasketShipmentObject {
  attributes: BasketShipmentObjectAttributes;
  id: string;
  relationships: BasketShipmentObjectRelationships;
  type: BasketShipmentObjectType;
}

export type BasketObjectType = typeof BasketObjectType[keyof typeof BasketObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketObjectType = {
  basket: 'basket',
} as const;

export type BasketObjectRelationships = {
  customer: Relationship;
  items: RelationshipList;
  restaurant?: Relationship;
  shipment: Relationship;
  voucher: Relationship;
};

export type BasketObjectMeta = {
  minimum_spend: string;
  minimum_spend_per_order: string;
  request_card: boolean;
};

export type BasketObjectAttributesType = typeof BasketObjectAttributesType[keyof typeof BasketObjectAttributesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketObjectAttributesType = {
  TakeawayBasket: 'TakeawayBasket',
  RecipeKitBasket: 'RecipeKitBasket',
} as const;

/**
 * @nullable
 */
export type BasketObjectAttributesFulfillmentType =
  | typeof BasketObjectAttributesFulfillmentType[keyof typeof BasketObjectAttributesFulfillmentType]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketObjectAttributesFulfillmentType = {
  delivery: 'delivery',
  pickup: 'pickup',
  eat_in: 'eat_in',
} as const;

export type BasketObjectAttributesAddress = {
  /** @nullable */
  address_provider: string | null;
  /** @nullable */
  address1: string | null;
  /** @nullable */
  address2: string | null;
  /** @nullable */
  city: string | null;
  /** @nullable */
  company: string | null;
  /** @nullable */
  country: string | null;
  /** @nullable */
  country_code: string | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  lat: string | null;
  /** @nullable */
  lon: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  region: string | null;
  /** @nullable */
  region_code: string | null;
  /** @nullable */
  zip: string | null;
};

export type BasketObjectAttributes = {
  address: BasketObjectAttributesAddress;
  /** @nullable */
  asap: boolean | null;
  /** @nullable */
  customer_email: string | null;
  /** @nullable */
  delivery_date: string | null;
  /** @nullable */
  delivery_day?: string | null;
  /** @nullable */
  delivery_note?: string | null;
  /** @nullable */
  frequency?: number | null;
  /** @nullable */
  fulfillment_type: BasketObjectAttributesFulfillmentType;
  one_off_fee_applicable?: boolean;
  start_subscription?: boolean;
  subtotal_price: string;
  /** @nullable */
  table_number: string | null;
  /** @nullable */
  time_slot: string | null;
  /** @nullable */
  time_slot_end_time: string | null;
  total_discount: string;
  total_line_items_price: string;
  total_price: string;
  type: BasketObjectAttributesType;
};

export interface BasketObject {
  attributes: BasketObjectAttributes;
  id: string;
  meta: BasketObjectMeta;
  relationships: BasketObjectRelationships;
  type: BasketObjectType;
}

export interface BasketItemPayload {
  meal_week?: string;
  product_variant_id: string;
  quantity: number;
  sub_items?: BasketItemPayload[];
}

export type BasketItemObjectType = typeof BasketItemObjectType[keyof typeof BasketItemObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketItemObjectType = {
  basket_item: 'basket_item',
} as const;

export type BasketItemObjectAttributes = {
  discount: string;
  /** @nullable */
  image_url: string | null;
  is_addon: boolean;
  is_double: boolean;
  /** @nullable */
  meal_week: string | null;
  price: string;
  product_id: number;
  /** @nullable */
  product_variant_ext_id: string | null;
  product_variant_id: number;
  quantity: number;
  /** @nullable */
  title: string | null;
  /** @nullable */
  type: string | null;
};

export interface BasketItemObject {
  attributes: BasketItemObjectAttributes;
  id: string;
  type: BasketItemObjectType;
}

export type BasketIncludedItem =
  | BasketItemObject
  | TakeawayBasketItemObject
  | VoucherObject
  | BasketShipmentObject
  | ShipmentItemObject
  | ProductVariantObject;

export interface Basket {
  data: BasketObject;
  included?: BasketIncludedItem[];
}

export type AdminAdmin = {
  data: AdminAdminData;
};

export interface Admin {
  admin: AdminAdmin;
}

export type AdminAdminDataType = typeof AdminAdminDataType[keyof typeof AdminAdminDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminAdminDataType = {
  admin: 'admin',
} as const;

export type AdminAdminDataAttributesPermissions = { [key: string]: boolean };

export type AdminAdminDataAttributes = {
  /** @nullable */
  email: string | null;
  /** @nullable */
  full_name: string | null;
  permissions: AdminAdminDataAttributesPermissions;
};

export type AdminAdminData = {
  attributes: AdminAdminDataAttributes;
  id: string;
  type: AdminAdminDataType;
};

export interface Addresses {
  data: AddressObject[];
}

export type AddressObjectType = typeof AddressObjectType[keyof typeof AddressObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressObjectType = {
  address: 'address',
} as const;

export type AddressObjectRelationships = {
  customer: Relationship;
};

export type AddressObjectAttributes = {
  address1: string;
  /** @nullable */
  address2: string | null;
  city: string;
  /** @nullable */
  company: string | null;
  country: string;
  country_code: string;
  /** @nullable */
  ext_id: number | null;
  /** @nullable */
  first_name: string | null;
  /** @nullable */
  is_default: boolean | null;
  /** @nullable */
  last_name: string | null;
  /** @nullable */
  last_used: boolean | null;
  /** @nullable */
  lat: string | null;
  /** @nullable */
  lon: string | null;
  /** @nullable */
  phone: string | null;
  /** @nullable */
  region: string | null;
  /** @nullable */
  region_code: string | null;
  zip: string;
};

export interface AddressObject {
  attributes: AddressObjectAttributes;
  id: string;
  relationships: AddressObjectRelationships;
  type: AddressObjectType;
}

export interface Address {
  data: AddressObject;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Updates Address to be the default one
 */
export const postApiV2AddressesAddressIdDefault = (
  addressId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Address>(
    { url: `/api/v2/addresses/${addressId}/default`, method: 'POST' },
    options
  );
};

/**
 * @summary Returns Customer's Addresses
 */
export const getApiV2Addresses = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Addresses>({ url: `/api/v2/addresses`, method: 'GET' }, options);
};

/**
 * @summary Creates an Address
 */
export const postApiV2Addresses = (
  postApiV2AddressesBody: BodyType<PostApiV2AddressesBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Address>(
    {
      url: `/api/v2/addresses`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2AddressesBody,
    },
    options
  );
};

/**
 * @summary Returns Address
 */
export const getApiV2AddressesId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Address>({ url: `/api/v2/addresses/${id}`, method: 'GET' }, options);
};

/**
 * @summary Returns updated Address
 */
export const putApiV2AddressesId = (
  id: string,
  putApiV2AddressesIdBody: BodyType<PutApiV2AddressesIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Address>(
    {
      url: `/api/v2/addresses/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2AddressesIdBody,
    },
    options
  );
};

/**
 * @summary Destroys an Address
 */
export const deleteApiV2AddressesId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>({ url: `/api/v2/addresses/${id}`, method: 'DELETE' }, options);
};

/**
 * @summary Returns Admin
 */
export const getApiV2AdminMe = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Admin>({ url: `/api/v2/admin_me`, method: 'GET' }, options);
};

/**
 * @summary Calculate Delivery Estimation
 */
export const postApiV2DeliveryEstimation = (
  postApiV2DeliveryEstimationBody: BodyType<PostApiV2DeliveryEstimationBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2DeliveryEstimation200>(
    {
      url: `/api/v2/delivery_estimation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2DeliveryEstimationBody,
    },
    options
  );
};

/**
 * @summary Creates a BasketItem
 */
export const postApiV2BasketsBasketIdItems = (
  basketId: string,
  basketItemPayload: BodyType<BasketItemPayload>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    {
      url: `/api/v2/baskets/${basketId}/items`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: basketItemPayload,
    },
    options
  );
};

/**
 * @summary Updates a BasketItem
 */
export const putApiV2BasketsBasketIdItemsId = (
  basketId: string,
  id: string,
  putApiV2BasketsBasketIdItemsIdBody: BodyType<PutApiV2BasketsBasketIdItemsIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    {
      url: `/api/v2/baskets/${basketId}/items/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2BasketsBasketIdItemsIdBody,
    },
    options
  );
};

/**
 * @summary Deletes a BasketItem
 */
export const deleteApiV2BasketsBasketIdItemsId = (
  basketId: string,
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    { url: `/api/v2/baskets/${basketId}/items/${id}`, method: 'DELETE' },
    options
  );
};

/**
 * @summary Deletes multiple BasketItems
 */
export const deleteApiV2BasketsBasketIdItemsMany = (
  basketId: string,
  deleteApiV2BasketsBasketIdItemsManyBody: BodyType<DeleteApiV2BasketsBasketIdItemsManyBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    {
      url: `/api/v2/baskets/${basketId}/items/many`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteApiV2BasketsBasketIdItemsManyBody,
    },
    options
  );
};

/**
 * @summary Returns basket total with and without fee
 */
export const postApiV2BasketsBasketIdPreviewOneOffFee = (
  basketId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2BasketsBasketIdPreviewOneOffFee200>(
    { url: `/api/v2/baskets/${basketId}/preview_one_off_fee`, method: 'POST' },
    options
  );
};

/**
 * @summary Applies a voucher to a customer's basket
 */
export const postApiV2BasketsBasketIdVouchers = (
  basketId: string,
  postApiV2BasketsBasketIdVouchersBody: BodyType<PostApiV2BasketsBasketIdVouchersBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    {
      url: `/api/v2/baskets/${basketId}/vouchers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2BasketsBasketIdVouchersBody,
    },
    options
  );
};

/**
 * @summary Validates a voucher is still valid for a customer's basket
 */
export const getApiV2BasketsBasketIdVouchers = (
  basketId: string,
  params?: GetApiV2BasketsBasketIdVouchersParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    { url: `/api/v2/baskets/${basketId}/vouchers`, method: 'GET', params },
    options
  );
};

/**
 * @summary Remove a voucher from a customer's basket
 */
export const deleteApiV2BasketsBasketIdVouchersId = (
  basketId: string,
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    { url: `/api/v2/baskets/${basketId}/vouchers/${id}`, method: 'DELETE' },
    options
  );
};

/**
 * @summary Returns Customer's Baskets
 */
export const getApiV2Baskets = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Baskets>({ url: `/api/v2/baskets`, method: 'GET' }, options);
};

/**
 * @summary Creates a Basket
 */
export const postApiV2Baskets = (
  postApiV2BasketsBody: BodyType<PostApiV2BasketsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Baskets>(
    {
      url: `/api/v2/baskets`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2BasketsBody,
    },
    options
  );
};

/**
 * @summary Returns a Basket
 */
export const getApiV2BasketsId = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Basket>({ url: `/api/v2/baskets/${id}`, method: 'GET' }, options);
};

/**
 * @summary Updates a Basket
 */
export const putApiV2BasketsId = (
  id: string,
  putApiV2BasketsIdBody: BodyType<PutApiV2BasketsIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    {
      url: `/api/v2/baskets/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2BasketsIdBody,
    },
    options
  );
};

/**
 * @summary Destroys Customer's Basket
 */
export const deleteApiV2BasketsId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>({ url: `/api/v2/baskets/${id}`, method: 'DELETE' }, options);
};

/**
 * @summary Resets the Basket delivery date
 */
export const postApiV2BasketsIdResetDeliveryDate = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Basket>(
    { url: `/api/v2/baskets/${id}/reset_delivery_date`, method: 'POST' },
    options
  );
};

/**
 * @summary Deletes all of the customer's baskets
 */
export const deleteApiV2BasketsAll = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<EmptyObject>({ url: `/api/v2/baskets/all`, method: 'DELETE' }, options);
};

/**
 * @summary Deletes a basket by type
 */
export const deleteApiV2BasketsDeleteByTypeType = (
  type: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    { url: `/api/v2/baskets/delete_by_type/${type}`, method: 'DELETE' },
    options
  );
};

/**
 * @summary Validates a basket
 */
export const getApiV2BasketsIdValidate = (
  id: string,
  params?: GetApiV2BasketsIdValidateParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetApiV2BasketsIdValidate200>(
    { url: `/api/v2/baskets/${id}/validate`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns product bundles
 */
export const getApiV2Bundles = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Bundles>({ url: `/api/v2/bundles`, method: 'GET' }, options);
};

/**
 * @summary Returns constrained dates for a given week
 */
export const getApiV2Constraints = (
  params?: GetApiV2ConstraintsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ConstrainedDates>(
    { url: `/api/v2/constraints`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns constrained dates for a given week considering product variants
 */
export const postApiV2Constraints = (
  postApiV2ConstraintsBody: BodyType<PostApiV2ConstraintsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ConstrainedDates>(
    {
      url: `/api/v2/constraints`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2ConstraintsBody,
    },
    options
  );
};

/**
 * @summary Returns courier delivery instructions
 */
export const getApiV2CourierDeliveryInstructions = (
  params?: GetApiV2CourierDeliveryInstructionsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CourierDeliveryInstructions>(
    { url: `/api/v2/courier_delivery_instructions`, method: 'GET', params },
    options
  );
};

/**
 * @summary Creates a CustomerGoogleExperiment
 */
export const postApiV2CustomerGoogleExperiments = (
  postApiV2CustomerGoogleExperimentsBody: BodyType<PostApiV2CustomerGoogleExperimentsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/customer_google_experiments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomerGoogleExperimentsBody,
    },
    options
  );
};

/**
 * @summary Request a Shopify account invitation e-mail
 */
export const postApiV2CustomersAccountSetup = (
  postApiV2CustomersAccountSetupBody: BodyType<PostApiV2CustomersAccountSetupBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/customers/account_setup`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomersAccountSetupBody,
    },
    options
  );
};

/**
 * @summary Redeems a credit voucher
 */
export const postApiV2CustomersCreditVoucher = (
  postApiV2CustomersCreditVoucherBody: BodyType<PostApiV2CustomersCreditVoucherBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/credit_voucher`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomersCreditVoucherBody,
    },
    options
  );
};

/**
 * @summary Updates a Customer's email
 */
export const putApiV2CustomersEmail = (
  putApiV2CustomersEmailBody: BodyType<PutApiV2CustomersEmailBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/email`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2CustomersEmailBody,
    },
    options
  );
};

/**
 * @summary Sets a Customer's fingerprint
 */
export const postApiV2CustomersFingerprint = (
  postApiV2CustomersFingerprintBody: BodyType<PostApiV2CustomersFingerprintBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/fingerprint`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomersFingerprintBody,
    },
    options
  );
};

/**
 * @summary Changes a Customer's paperless preference
 */
export const putApiV2CustomersPaperless = (
  putApiV2CustomersPaperlessBody: BodyType<PutApiV2CustomersPaperlessBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/paperless`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2CustomersPaperlessBody,
    },
    options
  );
};

/**
 * @summary Request a password reset e-mail
 */
export const postApiV2CustomersPasswordReset = (
  postApiV2CustomersPasswordResetBody: BodyType<PostApiV2CustomersPasswordResetBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/customers/password_reset`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomersPasswordResetBody,
    },
    options
  );
};

/**
 * @summary Updates a Customer's password
 */
export const putApiV2CustomersPassword = (
  putApiV2CustomersPasswordBody: BodyType<PutApiV2CustomersPasswordBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/password`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2CustomersPasswordBody,
    },
    options
  );
};

/**
 * @summary Updates a Customer's phone
 */
export const putApiV2CustomersPhone = (
  putApiV2CustomersPhoneBody: BodyType<PutApiV2CustomersPhoneBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers/phone`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2CustomersPhoneBody,
    },
    options
  );
};

/**
 * @summary Creates a Customer
 */
export const postApiV2Customers = (
  postApiV2CustomersBody: BodyType<PostApiV2CustomersBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/customers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CustomersBody,
    },
    options
  );
};

/**
 * @summary Calculates delivery dates
 */
export const postApiV2DeliveryDates = (
  postApiV2DeliveryDatesBody: BodyType<PostApiV2DeliveryDatesBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2DeliveryDates200>(
    {
      url: `/api/v2/delivery_dates`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2DeliveryDatesBody,
    },
    options
  );
};

/**
 * @summary Retrieves default delivery fee configuration
 */
export const getApiV2DeliveryFeeConfig = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<DeliveryFeeConfig>(
    { url: `/api/v2/delivery_fee_config`, method: 'GET' },
    options
  );
};

/**
 * @summary Returns Google reviews
 */
export const getApiV2GoogleReviews = (
  params?: GetApiV2GoogleReviewsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GoogleReviews>(
    { url: `/api/v2/google_reviews`, method: 'GET', params },
    options
  );
};

/**
 * @summary Creates an image upload on AWS S3
 */
export const postApiV2ImageUploads = (
  postApiV2ImageUploadsBody: BodyType<PostApiV2ImageUploadsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2ImageUploads200>(
    {
      url: `/api/v2/image_uploads`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2ImageUploadsBody,
    },
    options
  );
};

/**
 * @summary Returns list of ingredients
 */
export const getApiV2Ingredients = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Ingredients>({ url: `/api/v2/ingredients`, method: 'GET' }, options);
};

/**
 * @summary Returns subscription's ingredient preferences
 */
export const getApiV2SubscriptionsSubscriptionIdIngredientPreferences = (
  subscriptionId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetApiV2SubscriptionsSubscriptionIdIngredientPreferences200>(
    { url: `/api/v2/subscriptions/${subscriptionId}/ingredient_preferences`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates subscription's ingredient preferences
 */
export const postApiV2SubscriptionsSubscriptionIdIngredientPreferences = (
  subscriptionId: string,
  postApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody: BodyType<PostApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SubscriptionsSubscriptionIdIngredientPreferences200>(
    {
      url: `/api/v2/subscriptions/${subscriptionId}/ingredient_preferences`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody,
    },
    options
  );
};

/**
 * @summary Returns active market banners
 */
export const getApiV2MarketingBanners = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MarketingBanners>(
    { url: `/api/v2/marketing_banners`, method: 'GET' },
    options
  );
};

/**
 * @summary Returns active marketing offers for given restaurant and fulfillment type
 */
export const getApiV2MarketingOffers = (
  params?: GetApiV2MarketingOffersParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<MarketingOffers>(
    { url: `/api/v2/marketing_offers`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns Customer
 */
export const getApiV2Me = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetApiV2Me200>({ url: `/api/v2/me`, method: 'GET' }, options);
};

/**
 * @summary Updates a Customer
 */
export const putApiV2Me = (
  putApiV2MeBody: BodyType<PutApiV2MeBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Customer>(
    {
      url: `/api/v2/me`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2MeBody,
    },
    options
  );
};

/**
 * @summary Triggers chef selection for an Order
 */
export const postApiV2OrdersOrderIdChefSelections = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    { url: `/api/v2/orders/${orderId}/chef_selections`, method: 'POST' },
    options
  );
};

/**
 * @summary Confirms Order's payment
 */
export const postApiV2OrdersConfirm = (
  postApiV2OrdersConfirmBody: BodyType<PostApiV2OrdersConfirmBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/orders/confirm`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersConfirmBody,
    },
    options
  );
};

/**
 * @summary Updates the delivery date of an Order
 */
export const postApiV2OrdersOrderIdDeliveryDate = (
  orderId: string,
  postApiV2OrdersOrderIdDeliveryDateBody: BodyType<PostApiV2OrdersOrderIdDeliveryDateBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    {
      url: `/api/v2/orders/${orderId}/delivery_date`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersOrderIdDeliveryDateBody,
    },
    options
  );
};

/**
 * @summary Fetches updated driver location for an Order
 */
export const getApiV2OrdersOrderIdDriverLocation = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetApiV2OrdersOrderIdDriverLocation200>(
    { url: `/api/v2/orders/${orderId}/driver_location`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates the feedback for an Order
 */
export const postApiV2OrdersOrderIdFeedback = (
  orderId: string,
  postApiV2OrdersOrderIdFeedbackBody: BodyType<PostApiV2OrdersOrderIdFeedbackBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderFeedback>(
    {
      url: `/api/v2/orders/${orderId}/feedback`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersOrderIdFeedbackBody,
    },
    options
  );
};

/**
 * @summary Creates Eat-In Takeaway Order with Guest Basket
 */
export const postApiV2OrdersGuest = (
  postApiV2OrdersGuestBody: BodyType<PostApiV2OrdersGuestBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderCreationResponse>(
    {
      url: `/api/v2/orders/guest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersGuestBody,
    },
    options
  );
};

/**
 * @summary Fetches live Order
 */
export const getApiV2OrdersLive = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Orders>({ url: `/api/v2/orders/live`, method: 'GET' }, options);
};

/**
 * @summary Returns Open Tab
 */
export const getApiV2OrdersOpenTab = (
  params?: GetApiV2OrdersOpenTabParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OpenTab>(
    { url: `/api/v2/orders/open_tab`, method: 'GET', params },
    options
  );
};

/**
 * @summary Creates Open Tab Order with Basket
 */
export const postApiV2OrdersOpenTab = (
  postApiV2OrdersOpenTabBody: BodyType<PostApiV2OrdersOpenTabBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OpenTab>(
    {
      url: `/api/v2/orders/open_tab`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersOpenTabBody,
    },
    options
  );
};

/**
 * @summary Close Open Tab & Checkout
 */
export const postApiV2OrdersOpenTabCheckout = (
  postApiV2OrdersOpenTabCheckoutBody: BodyType<PostApiV2OrdersOpenTabCheckoutBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderCreationResponse>(
    {
      url: `/api/v2/orders/open_tab/checkout`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersOpenTabCheckoutBody,
    },
    options
  );
};

/**
 * @summary Creates Recipe Kit Orders with Customer's Basket
 */
export const postApiV2OrdersRecipeKits = (
  postApiV2OrdersRecipeKitsBody: BodyType<PostApiV2OrdersRecipeKitsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderCreationResponse>(
    {
      url: `/api/v2/orders/recipe_kits`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersRecipeKitsBody,
    },
    options
  );
};

/**
 * @summary Removes a voucher from an order
 */
export const postApiV2OrdersOrderIdRemoveVoucher = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    { url: `/api/v2/orders/${orderId}/remove_voucher`, method: 'POST' },
    options
  );
};

/**
 * @summary Generates & sends order invoice e-mail
 */
export const postApiV2OrdersOrderIdSendInvoice = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>(
    { url: `/api/v2/orders/${orderId}/send_invoice`, method: 'POST' },
    options
  );
};

/**
 * @summary Updates the shipping address of an Order
 */
export const putApiV2OrdersOrderIdShippingAddressId = (
  orderId: string,
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    { url: `/api/v2/orders/${orderId}/shipping_address/${id}`, method: 'PUT' },
    options
  );
};

/**
 * @summary Creates Takeaway Order with Customer's Basket
 */
export const postApiV2OrdersTakeaway = (
  postApiV2OrdersTakeawayBody: BodyType<PostApiV2OrdersTakeawayBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderCreationResponse>(
    {
      url: `/api/v2/orders/takeaway`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersTakeawayBody,
    },
    options
  );
};

/**
 * @summary Validate & redeem a voucher on an order
 */
export const postApiV2OrdersOrderIdVoucher = (
  orderId: string,
  postApiV2OrdersOrderIdVoucherBody: BodyType<PostApiV2OrdersOrderIdVoucherBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    {
      url: `/api/v2/orders/${orderId}/voucher`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersOrderIdVoucherBody,
    },
    options
  );
};

/**
 * @summary Returns Customer's Orders
 */
export const getApiV2Orders = (
  params?: GetApiV2OrdersParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Orders>({ url: `/api/v2/orders`, method: 'GET', params }, options);
};

/**
 * @summary Creates an upcoming Order
 */
export const postApiV2Orders = (
  postApiV2OrdersBody: BodyType<PostApiV2OrdersBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    {
      url: `/api/v2/orders`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2OrdersBody,
    },
    options
  );
};

/**
 * @summary Returns Order
 */
export const getApiV2OrdersId = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Order>({ url: `/api/v2/orders/${id}`, method: 'GET' }, options);
};

/**
 * @summary Updates Order's Line Items
 */
export const putApiV2OrdersId = (
  id: string,
  putApiV2OrdersIdBody: BodyType<PutApiV2OrdersIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    {
      url: `/api/v2/orders/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2OrdersIdBody,
    },
    options
  );
};

/**
 * @summary Returns payment details for a payment
 */
export const getApiV2PaymentDetailsPaymentId = (
  paymentId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetApiV2PaymentDetailsPaymentId200>(
    { url: `/api/v2/payment_details/${paymentId}`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates PaymentMethod to be the default one
 */
export const postApiV2PaymentMethodsPaymentMethodIdDefault = (
  paymentMethodId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2PaymentMethodsPaymentMethodIdDefault200>(
    { url: `/api/v2/payment_methods/${paymentMethodId}/default`, method: 'POST' },
    options
  );
};

/**
 * @summary Creates a Setup Intent
 */
export const postApiV2PaymentMethodsSetup = (
  postApiV2PaymentMethodsSetupBody: BodyType<PostApiV2PaymentMethodsSetupBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2PaymentMethodsSetup200>(
    {
      url: `/api/v2/payment_methods/setup`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2PaymentMethodsSetupBody,
    },
    options
  );
};

/**
 * @summary Returns Customer's Payment Methods
 */
export const getApiV2PaymentMethods = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetApiV2PaymentMethods200>(
    { url: `/api/v2/payment_methods`, method: 'GET' },
    options
  );
};

/**
 * @summary Creates a Payment Method
 */
export const postApiV2PaymentMethods = (
  postApiV2PaymentMethodsBody: BodyType<PostApiV2PaymentMethodsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2PaymentMethods200>(
    {
      url: `/api/v2/payment_methods`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2PaymentMethodsBody,
    },
    options
  );
};

/**
 * @summary Deletes a Payment Method
 */
export const deleteApiV2PaymentMethodsId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    { url: `/api/v2/payment_methods/${id}`, method: 'DELETE' },
    options
  );
};

/**
 * @summary Creates a payment for an order
 */
export const postApiV2Payments = (
  postApiV2PaymentsBody: BodyType<PostApiV2PaymentsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PaymentInfo>(
    {
      url: `/api/v2/payments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2PaymentsBody,
    },
    options
  );
};

/**
 * @summary Calculates totals and fees without saving the order
 */
export const postApiV2PreviewOrder = (
  postApiV2PreviewOrderBody: BodyType<PostApiV2PreviewOrderBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    {
      url: `/api/v2/preview_order`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2PreviewOrderBody,
    },
    options
  );
};

/**
 * @summary Retrieves orders data to print tickets
 */
export const getApiV2OrdersPrintableTickets = (
  params?: GetApiV2OrdersPrintableTicketsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PrintableOrders>(
    { url: `/api/v2/orders/printable_tickets`, method: 'GET', params },
    options
  );
};

/**
 * @summary Increase the print count for the given order
 */
export const postApiV2OrdersOrderIdPrintTicket = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderTicket>(
    { url: `/api/v2/orders/${orderId}/print_ticket`, method: 'POST' },
    options
  );
};

/**
 * @summary Creates a Product Rating
 */
export const postApiV2ProductRatings = (
  postApiV2ProductRatingsBody: BodyType<PostApiV2ProductRatingsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2ProductRatings200>(
    {
      url: `/api/v2/product_ratings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2ProductRatingsBody,
    },
    options
  );
};

/**
 * @summary Returns product review summaries for given menu
 */
export const getApiV2WeeklyMenusStartDateReviewSummary = (
  startDate: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<WeeklyMenuReviewSummary>(
    { url: `/api/v2/weekly_menus/${startDate}/review_summary`, method: 'GET' },
    options
  );
};

/**
 * @summary Creates a Rating
 */
export const postApiV2Ratings = (
  postApiV2RatingsBody: BodyType<PostApiV2RatingsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Rating>(
    {
      url: `/api/v2/ratings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2RatingsBody,
    },
    options
  );
};

/**
 * @summary Updates a Rating
 */
export const putApiV2RatingsId = (
  id: string,
  putApiV2RatingsIdBody: BodyType<PutApiV2RatingsIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Rating>(
    {
      url: `/api/v2/ratings/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2RatingsIdBody,
    },
    options
  );
};

/**
 * @summary Returns nearest restaurants for the given lat, lon and fulfillment type
 */
export const getApiV2RestaurantsNearest = (
  params?: GetApiV2RestaurantsNearestParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Restaurants>(
    { url: `/api/v2/restaurants/nearest`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns restaurants for admin
 */
export const getApiV2Restaurants = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Restaurants>({ url: `/api/v2/restaurants`, method: 'GET' }, options);
};

/**
 * @summary Returns restaurant
 */
export const getApiV2RestaurantsId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Restaurant>({ url: `/api/v2/restaurants/${id}`, method: 'GET' }, options);
};

/**
 * @summary Returns restaurant menu
 */
export const getApiV2RestaurantsIdMenu = (
  id: string,
  params?: GetApiV2RestaurantsIdMenuParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TakeawayMenu>(
    { url: `/api/v2/restaurants/${id}/menu`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns restaurant's snoozed products
 */
export const getApiV2RestaurantsIdSnoozedProducts = (
  id: string,
  params?: GetApiV2RestaurantsIdSnoozedProductsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TakeawayMenuProducts>(
    { url: `/api/v2/restaurants/${id}/snoozed_products`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns restaurant's time slots
 */
export const getApiV2RestaurantsIdTimeSlots = (
  id: string,
  params: GetApiV2RestaurantsIdTimeSlotsParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetApiV2RestaurantsIdTimeSlots200Item[]>(
    { url: `/api/v2/restaurants/${id}/time_slots`, method: 'GET', params },
    options
  );
};

/**
 * @summary Returns active reward blocks
 */
export const getApiV2RewardBlocks = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RewardBlocks>({ url: `/api/v2/reward_blocks`, method: 'GET' }, options);
};

/**
 * @summary Shopify theme login for account settings
 */
export const postApiV2Account = (
  postApiV2AccountBody: BodyType<PostApiV2AccountBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>(
    {
      url: `/api/v2/account`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2AccountBody,
    },
    options
  );
};

/**
 * @summary Login with an Apple authorization code
 */
export const postApiV2SessionsApple = (
  postApiV2SessionsAppleBody: BodyType<PostApiV2SessionsAppleBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SessionsApple200>(
    {
      url: `/api/v2/sessions/apple`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsAppleBody,
    },
    options
  );
};

/**
 * @summary Shopify theme login for subscription funnel
 */
export const postApiV2Checkout = (
  postApiV2CheckoutBody: BodyType<PostApiV2CheckoutBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>(
    {
      url: `/api/v2/checkout`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2CheckoutBody,
    },
    options
  );
};

/**
 * @summary Login / Signup with email + password
 */
export const postApiV2SessionsEmail = (
  postApiV2SessionsEmailBody: BodyType<PostApiV2SessionsEmailBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SessionsEmail200>(
    {
      url: `/api/v2/sessions/email`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsEmailBody,
    },
    options
  );
};

/**
 * @summary Login with a Facebook token
 */
export const postApiV2SessionsFacebook = (
  postApiV2SessionsFacebookBody: BodyType<PostApiV2SessionsFacebookBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SessionsFacebook200>(
    {
      url: `/api/v2/sessions/facebook`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsFacebookBody,
    },
    options
  );
};

/**
 * @summary Login with a Google ID token
 */
export const postApiV2SessionsGoogle = (
  postApiV2SessionsGoogleBody: BodyType<PostApiV2SessionsGoogleBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SessionsGoogle200>(
    {
      url: `/api/v2/sessions/google`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsGoogleBody,
    },
    options
  );
};

/**
 * @summary Set a guest cookie with a guest token
 */
export const postApiV2SessionsGuestToken = (
  postApiV2SessionsGuestTokenBody: BodyType<PostApiV2SessionsGuestTokenBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>(
    {
      url: `/api/v2/sessions/guest_token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsGuestTokenBody,
    },
    options
  );
};

/**
 * @summary Login with a token
 */
export const postApiV2SessionsToken = (
  postApiV2SessionsTokenBody: BodyType<PostApiV2SessionsTokenBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<unknown>(
    {
      url: `/api/v2/sessions/token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsTokenBody,
    },
    options
  );
};

/**
 * @summary Generates jwt token with admin and restaurant
 */
export const postApiV2SessionsAdmin = (
  postApiV2SessionsAdminBody: BodyType<PostApiV2SessionsAdminBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2SessionsAdmin200>(
    {
      url: `/api/v2/sessions/admin`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsAdminBody,
    },
    options
  );
};

/**
 * @summary Sets cookie with JWT and generates Multipass URL
 */
export const postApiV2Sessions = (
  postApiV2SessionsBody: BodyType<PostApiV2SessionsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PostApiV2Sessions200>(
    {
      url: `/api/v2/sessions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SessionsBody,
    },
    options
  );
};

/**
 * @summary Destroys cookie
 */
export const deleteApiV2Sessions = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/v2/sessions`, method: 'DELETE' }, options);
};

/**
 * @summary Get settings
 */
export const getApiV2Settings = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetApiV2Settings200>({ url: `/api/v2/settings`, method: 'GET' }, options);
};

/**
 * @summary Retrieves store credit transactions
 */
export const getApiV2StoreCreditTransactions = (
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<StoreCreditTransactions>(
    { url: `/api/v2/store_credit_transactions`, method: 'GET' },
    options
  );
};

/**
 * @summary Triggers order creation & chef selection for a Schedule Week
 */
export const postApiV2ScheduleWeeksSubscriptionScheduleWeekIdChefSelections = (
  subscriptionScheduleWeekId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Order>(
    { url: `/api/v2/schedule_weeks/${subscriptionScheduleWeekId}/chef_selections`, method: 'POST' },
    options
  );
};

/**
 * @summary Skips a Schedule Week
 */
export const postApiV2ScheduleWeeksSubscriptionScheduleWeekIdSkip = (
  subscriptionScheduleWeekId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SubscriptionScheduleWeek>(
    { url: `/api/v2/schedule_weeks/${subscriptionScheduleWeekId}/skip`, method: 'POST' },
    options
  );
};

/**
 * @summary Unskips a Schedule Week
 */
export const postApiV2ScheduleWeeksSubscriptionScheduleWeekIdUnskip = (
  subscriptionScheduleWeekId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SubscriptionScheduleWeek>(
    { url: `/api/v2/schedule_weeks/${subscriptionScheduleWeekId}/unskip`, method: 'POST' },
    options
  );
};

/**
 * @summary Returns a Customer's Schedule Weeks
 */
export const getApiV2ScheduleWeeks = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SubscriptionScheduleWeeks>(
    { url: `/api/v2/schedule_weeks`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates a Schedule Week
 */
export const putApiV2ScheduleWeeksId = (
  id: string,
  putApiV2ScheduleWeeksIdBody: BodyType<PutApiV2ScheduleWeeksIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SubscriptionScheduleWeek>(
    {
      url: `/api/v2/schedule_weeks/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2ScheduleWeeksIdBody,
    },
    options
  );
};

/**
 * @summary Returns a Subscription's Schedule
 */
export const getApiV2SubscriptionsIdSchedule = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SubscriptionSchedule>(
    { url: `/api/v2/subscriptions/${id}/schedule`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates a Subscription's Schedule
 */
export const putApiV2SubscriptionsIdSchedule = (
  id: string,
  putApiV2SubscriptionsIdScheduleBody: BodyType<PutApiV2SubscriptionsIdScheduleBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SubscriptionSchedule>(
    {
      url: `/api/v2/subscriptions/${id}/schedule`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2SubscriptionsIdScheduleBody,
    },
    options
  );
};

/**
 * @summary Activates a Subscription
 */
export const postApiV2SubscriptionsSubscriptionIdActivate = (
  subscriptionId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    { url: `/api/v2/subscriptions/${subscriptionId}/activate`, method: 'POST' },
    options
  );
};

/**
 * @summary Cancels a Subscription
 */
export const postApiV2SubscriptionsSubscriptionIdCancel = (
  subscriptionId: string,
  postApiV2SubscriptionsSubscriptionIdCancelBody: BodyType<PostApiV2SubscriptionsSubscriptionIdCancelBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    {
      url: `/api/v2/subscriptions/${subscriptionId}/cancel`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SubscriptionsSubscriptionIdCancelBody,
    },
    options
  );
};

/**
 * @summary Changes Payment Method for all Subscriptions
 */
export const postApiV2SubscriptionsChangePaymentMethod = (
  postApiV2SubscriptionsChangePaymentMethodBody: BodyType<PostApiV2SubscriptionsChangePaymentMethodBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/subscriptions/change_payment_method`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SubscriptionsChangePaymentMethodBody,
    },
    options
  );
};

/**
 * @summary Pauses a Subscription
 */
export const postApiV2SubscriptionsSubscriptionIdPause = (
  subscriptionId: string,
  postApiV2SubscriptionsSubscriptionIdPauseBody: BodyType<PostApiV2SubscriptionsSubscriptionIdPauseBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    {
      url: `/api/v2/subscriptions/${subscriptionId}/pause`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SubscriptionsSubscriptionIdPauseBody,
    },
    options
  );
};

/**
 * @summary Returns Customer's Subscriptions
 */
export const getApiV2Subscriptions = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Subscriptions>({ url: `/api/v2/subscriptions`, method: 'GET' }, options);
};

/**
 * @summary Creates a Subscription
 */
export const postApiV2Subscriptions = (
  postApiV2SubscriptionsBody: BodyType<PostApiV2SubscriptionsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    {
      url: `/api/v2/subscriptions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2SubscriptionsBody,
    },
    options
  );
};

/**
 * @summary Returns Subscription
 */
export const getApiV2SubscriptionsId = (
  id: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    { url: `/api/v2/subscriptions/${id}`, method: 'GET' },
    options
  );
};

/**
 * @summary Updates a Subscription
 */
export const putApiV2SubscriptionsId = (
  id: string,
  putApiV2SubscriptionsIdBody: BodyType<PutApiV2SubscriptionsIdBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Subscription>(
    {
      url: `/api/v2/subscriptions/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putApiV2SubscriptionsIdBody,
    },
    options
  );
};

/**
 * @summary Snoozes takeaway menu product by id
 */
export const postApiV2TakeawayMenuProductsSnooze = (
  postApiV2TakeawayMenuProductsSnoozeBody: BodyType<PostApiV2TakeawayMenuProductsSnoozeBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/takeaway_menu_products/snooze`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2TakeawayMenuProductsSnoozeBody,
    },
    options
  );
};

/**
 * @summary Unsnoozes takeaway menu product by id
 */
export const postApiV2TakeawayMenuProductsUnsnooze = (
  postApiV2TakeawayMenuProductsUnsnoozeBody: BodyType<PostApiV2TakeawayMenuProductsUnsnoozeBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    {
      url: `/api/v2/takeaway_menu_products/unsnooze`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postApiV2TakeawayMenuProductsUnsnoozeBody,
    },
    options
  );
};

/**
 * @summary Accepts billed order
 */
export const postApiV2OrdersOrderIdAccept = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    { url: `/api/v2/orders/${orderId}/accept`, method: 'POST' },
    options
  );
};

/**
 * @summary Mark pickup order as delivered
 */
export const postApiV2OrdersOrderIdDeliver = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<EmptyObject>(
    { url: `/api/v2/orders/${orderId}/deliver`, method: 'POST' },
    options
  );
};

/**
 * @summary Get minimum supported versions
 */
export const getApiV2Versions = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Versions>({ url: `/api/v2/versions`, method: 'GET' }, options);
};

/**
 * @summary Returns voucher information
 */
export const getApiV2VouchersCode = (
  code: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Voucher>({ url: `/api/v2/vouchers/${code}`, method: 'GET' }, options);
};

export type PostApiV2AddressesAddressIdDefaultResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2AddressesAddressIdDefault>>
>;
export type GetApiV2AddressesResult = NonNullable<Awaited<ReturnType<typeof getApiV2Addresses>>>;
export type PostApiV2AddressesResult = NonNullable<Awaited<ReturnType<typeof postApiV2Addresses>>>;
export type GetApiV2AddressesIdResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2AddressesId>>
>;
export type PutApiV2AddressesIdResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2AddressesId>>
>;
export type DeleteApiV2AddressesIdResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2AddressesId>>
>;
export type GetApiV2AdminMeResult = NonNullable<Awaited<ReturnType<typeof getApiV2AdminMe>>>;
export type PostApiV2DeliveryEstimationResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2DeliveryEstimation>>
>;
export type PostApiV2BasketsBasketIdItemsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2BasketsBasketIdItems>>
>;
export type PutApiV2BasketsBasketIdItemsIdResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2BasketsBasketIdItemsId>>
>;
export type DeleteApiV2BasketsBasketIdItemsIdResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsBasketIdItemsId>>
>;
export type DeleteApiV2BasketsBasketIdItemsManyResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsBasketIdItemsMany>>
>;
export type PostApiV2BasketsBasketIdPreviewOneOffFeeResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2BasketsBasketIdPreviewOneOffFee>>
>;
export type PostApiV2BasketsBasketIdVouchersResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2BasketsBasketIdVouchers>>
>;
export type GetApiV2BasketsBasketIdVouchersResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2BasketsBasketIdVouchers>>
>;
export type DeleteApiV2BasketsBasketIdVouchersIdResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsBasketIdVouchersId>>
>;
export type GetApiV2BasketsResult = NonNullable<Awaited<ReturnType<typeof getApiV2Baskets>>>;
export type PostApiV2BasketsResult = NonNullable<Awaited<ReturnType<typeof postApiV2Baskets>>>;
export type GetApiV2BasketsIdResult = NonNullable<Awaited<ReturnType<typeof getApiV2BasketsId>>>;
export type PutApiV2BasketsIdResult = NonNullable<Awaited<ReturnType<typeof putApiV2BasketsId>>>;
export type DeleteApiV2BasketsIdResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsId>>
>;
export type PostApiV2BasketsIdResetDeliveryDateResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2BasketsIdResetDeliveryDate>>
>;
export type DeleteApiV2BasketsAllResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsAll>>
>;
export type DeleteApiV2BasketsDeleteByTypeTypeResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2BasketsDeleteByTypeType>>
>;
export type GetApiV2BasketsIdValidateResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2BasketsIdValidate>>
>;
export type GetApiV2BundlesResult = NonNullable<Awaited<ReturnType<typeof getApiV2Bundles>>>;
export type GetApiV2ConstraintsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Constraints>>
>;
export type PostApiV2ConstraintsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Constraints>>
>;
export type GetApiV2CourierDeliveryInstructionsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2CourierDeliveryInstructions>>
>;
export type PostApiV2CustomerGoogleExperimentsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2CustomerGoogleExperiments>>
>;
export type PostApiV2CustomersAccountSetupResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2CustomersAccountSetup>>
>;
export type PostApiV2CustomersCreditVoucherResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2CustomersCreditVoucher>>
>;
export type PutApiV2CustomersEmailResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2CustomersEmail>>
>;
export type PostApiV2CustomersFingerprintResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2CustomersFingerprint>>
>;
export type PutApiV2CustomersPaperlessResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2CustomersPaperless>>
>;
export type PostApiV2CustomersPasswordResetResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2CustomersPasswordReset>>
>;
export type PutApiV2CustomersPasswordResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2CustomersPassword>>
>;
export type PutApiV2CustomersPhoneResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2CustomersPhone>>
>;
export type PostApiV2CustomersResult = NonNullable<Awaited<ReturnType<typeof postApiV2Customers>>>;
export type PostApiV2DeliveryDatesResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2DeliveryDates>>
>;
export type GetApiV2DeliveryFeeConfigResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2DeliveryFeeConfig>>
>;
export type GetApiV2GoogleReviewsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2GoogleReviews>>
>;
export type PostApiV2ImageUploadsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ImageUploads>>
>;
export type GetApiV2IngredientsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Ingredients>>
>;
export type GetApiV2SubscriptionsSubscriptionIdIngredientPreferencesResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SubscriptionsSubscriptionIdIngredientPreferences>>
>;
export type PostApiV2SubscriptionsSubscriptionIdIngredientPreferencesResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SubscriptionsSubscriptionIdIngredientPreferences>>
>;
export type GetApiV2MarketingBannersResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2MarketingBanners>>
>;
export type GetApiV2MarketingOffersResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2MarketingOffers>>
>;
export type GetApiV2MeResult = NonNullable<Awaited<ReturnType<typeof getApiV2Me>>>;
export type PutApiV2MeResult = NonNullable<Awaited<ReturnType<typeof putApiV2Me>>>;
export type PostApiV2OrdersOrderIdChefSelectionsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdChefSelections>>
>;
export type PostApiV2OrdersConfirmResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersConfirm>>
>;
export type PostApiV2OrdersOrderIdDeliveryDateResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdDeliveryDate>>
>;
export type GetApiV2OrdersOrderIdDriverLocationResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2OrdersOrderIdDriverLocation>>
>;
export type PostApiV2OrdersOrderIdFeedbackResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdFeedback>>
>;
export type PostApiV2OrdersGuestResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersGuest>>
>;
export type GetApiV2OrdersLiveResult = NonNullable<Awaited<ReturnType<typeof getApiV2OrdersLive>>>;
export type GetApiV2OrdersOpenTabResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2OrdersOpenTab>>
>;
export type PostApiV2OrdersOpenTabResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOpenTab>>
>;
export type PostApiV2OrdersOpenTabCheckoutResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOpenTabCheckout>>
>;
export type PostApiV2OrdersRecipeKitsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersRecipeKits>>
>;
export type PostApiV2OrdersOrderIdRemoveVoucherResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdRemoveVoucher>>
>;
export type PostApiV2OrdersOrderIdSendInvoiceResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdSendInvoice>>
>;
export type PutApiV2OrdersOrderIdShippingAddressIdResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2OrdersOrderIdShippingAddressId>>
>;
export type PostApiV2OrdersTakeawayResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersTakeaway>>
>;
export type PostApiV2OrdersOrderIdVoucherResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdVoucher>>
>;
export type GetApiV2OrdersResult = NonNullable<Awaited<ReturnType<typeof getApiV2Orders>>>;
export type PostApiV2OrdersResult = NonNullable<Awaited<ReturnType<typeof postApiV2Orders>>>;
export type GetApiV2OrdersIdResult = NonNullable<Awaited<ReturnType<typeof getApiV2OrdersId>>>;
export type PutApiV2OrdersIdResult = NonNullable<Awaited<ReturnType<typeof putApiV2OrdersId>>>;
export type GetApiV2PaymentDetailsPaymentIdResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PaymentDetailsPaymentId>>
>;
export type PostApiV2PaymentMethodsPaymentMethodIdDefaultResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PaymentMethodsPaymentMethodIdDefault>>
>;
export type PostApiV2PaymentMethodsSetupResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PaymentMethodsSetup>>
>;
export type GetApiV2PaymentMethodsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2PaymentMethods>>
>;
export type PostApiV2PaymentMethodsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PaymentMethods>>
>;
export type DeleteApiV2PaymentMethodsIdResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2PaymentMethodsId>>
>;
export type PostApiV2PaymentsResult = NonNullable<Awaited<ReturnType<typeof postApiV2Payments>>>;
export type PostApiV2PreviewOrderResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2PreviewOrder>>
>;
export type GetApiV2OrdersPrintableTicketsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2OrdersPrintableTickets>>
>;
export type PostApiV2OrdersOrderIdPrintTicketResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdPrintTicket>>
>;
export type PostApiV2ProductRatingsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ProductRatings>>
>;
export type GetApiV2WeeklyMenusStartDateReviewSummaryResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2WeeklyMenusStartDateReviewSummary>>
>;
export type PostApiV2RatingsResult = NonNullable<Awaited<ReturnType<typeof postApiV2Ratings>>>;
export type PutApiV2RatingsIdResult = NonNullable<Awaited<ReturnType<typeof putApiV2RatingsId>>>;
export type GetApiV2RestaurantsNearestResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RestaurantsNearest>>
>;
export type GetApiV2RestaurantsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Restaurants>>
>;
export type GetApiV2RestaurantsIdResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RestaurantsId>>
>;
export type GetApiV2RestaurantsIdMenuResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RestaurantsIdMenu>>
>;
export type GetApiV2RestaurantsIdSnoozedProductsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RestaurantsIdSnoozedProducts>>
>;
export type GetApiV2RestaurantsIdTimeSlotsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RestaurantsIdTimeSlots>>
>;
export type GetApiV2RewardBlocksResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2RewardBlocks>>
>;
export type PostApiV2AccountResult = NonNullable<Awaited<ReturnType<typeof postApiV2Account>>>;
export type PostApiV2SessionsAppleResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsApple>>
>;
export type PostApiV2CheckoutResult = NonNullable<Awaited<ReturnType<typeof postApiV2Checkout>>>;
export type PostApiV2SessionsEmailResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsEmail>>
>;
export type PostApiV2SessionsFacebookResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsFacebook>>
>;
export type PostApiV2SessionsGoogleResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsGoogle>>
>;
export type PostApiV2SessionsGuestTokenResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsGuestToken>>
>;
export type PostApiV2SessionsTokenResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsToken>>
>;
export type PostApiV2SessionsAdminResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SessionsAdmin>>
>;
export type PostApiV2SessionsResult = NonNullable<Awaited<ReturnType<typeof postApiV2Sessions>>>;
export type DeleteApiV2SessionsResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiV2Sessions>>
>;
export type GetApiV2SettingsResult = NonNullable<Awaited<ReturnType<typeof getApiV2Settings>>>;
export type GetApiV2StoreCreditTransactionsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2StoreCreditTransactions>>
>;
export type PostApiV2ScheduleWeeksSubscriptionScheduleWeekIdChefSelectionsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ScheduleWeeksSubscriptionScheduleWeekIdChefSelections>>
>;
export type PostApiV2ScheduleWeeksSubscriptionScheduleWeekIdSkipResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ScheduleWeeksSubscriptionScheduleWeekIdSkip>>
>;
export type PostApiV2ScheduleWeeksSubscriptionScheduleWeekIdUnskipResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2ScheduleWeeksSubscriptionScheduleWeekIdUnskip>>
>;
export type GetApiV2ScheduleWeeksResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2ScheduleWeeks>>
>;
export type PutApiV2ScheduleWeeksIdResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2ScheduleWeeksId>>
>;
export type GetApiV2SubscriptionsIdScheduleResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SubscriptionsIdSchedule>>
>;
export type PutApiV2SubscriptionsIdScheduleResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2SubscriptionsIdSchedule>>
>;
export type PostApiV2SubscriptionsSubscriptionIdActivateResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SubscriptionsSubscriptionIdActivate>>
>;
export type PostApiV2SubscriptionsSubscriptionIdCancelResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SubscriptionsSubscriptionIdCancel>>
>;
export type PostApiV2SubscriptionsChangePaymentMethodResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SubscriptionsChangePaymentMethod>>
>;
export type PostApiV2SubscriptionsSubscriptionIdPauseResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2SubscriptionsSubscriptionIdPause>>
>;
export type GetApiV2SubscriptionsResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2Subscriptions>>
>;
export type PostApiV2SubscriptionsResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2Subscriptions>>
>;
export type GetApiV2SubscriptionsIdResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2SubscriptionsId>>
>;
export type PutApiV2SubscriptionsIdResult = NonNullable<
  Awaited<ReturnType<typeof putApiV2SubscriptionsId>>
>;
export type PostApiV2TakeawayMenuProductsSnoozeResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2TakeawayMenuProductsSnooze>>
>;
export type PostApiV2TakeawayMenuProductsUnsnoozeResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2TakeawayMenuProductsUnsnooze>>
>;
export type PostApiV2OrdersOrderIdAcceptResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdAccept>>
>;
export type PostApiV2OrdersOrderIdDeliverResult = NonNullable<
  Awaited<ReturnType<typeof postApiV2OrdersOrderIdDeliver>>
>;
export type GetApiV2VersionsResult = NonNullable<Awaited<ReturnType<typeof getApiV2Versions>>>;
export type GetApiV2VouchersCodeResult = NonNullable<
  Awaited<ReturnType<typeof getApiV2VouchersCode>>
>;
