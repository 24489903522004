import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as BasketApi from '@/api/basket';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';

type SkipOptions = {
  config?: MutationConfig<typeof BasketApi.updateBasket>;
};

const useUpdateBasket = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: BasketApi.updateBasket,
    onSuccess: (result, { id: basketId }) => {
      queryClient.setQueriesData(queries.baskets.byId(basketId), result);
      queryClient.invalidateQueries(queries.baskets.byId(basketId));
      queryClient.invalidateQueries({ queryKey: queries.takeaway.eta._def });
    },
  });
};

export default useUpdateBasket;
