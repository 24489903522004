import { ApiResponse } from '@pasta-evangelists/pasta-types';
import {
  deleteApiV2PaymentMethodsId,
  getApiV2PaymentDetailsPaymentId,
  getApiV2PaymentMethods,
  postApiV2PaymentMethods,
  postApiV2PaymentMethodsSetup,
} from './pensa';

export type AuthenticateSetup = {
  amount: number;
  clientSecret: string;
  deliveryDate: string;
  paymentMethodId: string;
  statusCode: string | null;
};

export const getSetupIntent = async (
  type: 'CreditCard' | 'PaypalAccount'
): ApiResponse<{ setupIntent: string }> => {
  try {
    const result = await postApiV2PaymentMethodsSetup({
      type,
      paymentProcessorName: 'Stripe',
    });

    return { data: result, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Request failed' } };
  }
};

export const createPaymentMethod = async ({
  setupIntentId,
  paymentProcessorName = 'Stripe',
  type = 'CreditCard',
}: {
  setupIntentId: string;
  paymentProcessorName?: string;
  type: 'CreditCard' | 'PaypalAccount';
}) => {
  const params = {
    setupIntentId,
    paymentProcessorName,
    type,
  };

  const result = await postApiV2PaymentMethods(params);

  return result.data;
};

export const getPaymentMethods = async () => {
  const result = await getApiV2PaymentMethods();
  return result.data;
};

export const deletePaymentMethod = async (id: string) => {
  await deleteApiV2PaymentMethodsId(id);
  return true;
};

export const getPaymentDetails = async (paymentIntentId: string) => {
  try {
    const result = await getApiV2PaymentDetailsPaymentId(paymentIntentId);
    return result;
  } catch (e: any) {
    if (e?.response?.data?.error) {
      throw new Error(e?.response?.data?.error);
    } else throw new Error('Something went wrong');
  }
};
