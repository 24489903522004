import { useQuery } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { convertAddressIntoState } from '@/stores/addressStore';
import currentCustomerStore from '@/stores/currentCustomerStore';
import { AddressObject, getApiV2Addresses } from '@/api/pensa';
import { AddressNonNullable } from '@/model';

const convertAddressServerResponseIntoState = (
  response: ObjectToCamel<AddressObject>[]
): AddressNonNullable[] =>
  response.reduce((acc, curr) => {
    acc.push(convertAddressIntoState(curr));
    return acc;
  }, [] as AddressNonNullable[]);

const loadAddresses = async () => {
  try {
    const result = await getApiV2Addresses();

    const validAddresses = result.data.filter(
      address =>
        address.attributes.address1 ||
        address.attributes.address2 ||
        address.attributes.phone ||
        address.attributes.city ||
        address.attributes.zip
    );

    return convertAddressServerResponseIntoState(validAddresses);
  } catch (e) {
    throw new Error("Couldn't load addresses list");
  }
};

const useGetAddresses = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useQuery({
    ...queries.addresses.all,
    queryFn: () => loadAddresses(),
    enabled,
  });
};

export default useGetAddresses;
