import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';
import { convertAddressIntoState } from '@/stores/addressStore';
import { postApiV2Addresses } from '@/api/pensa';
import { AddressNonNullable } from '@/model';

type SkipOptions = {
  config?: MutationConfig<typeof postApiV2Addresses>;
};

const useSaveAddress = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: postApiV2Addresses,
    onSuccess: result => {
      queryClient.setQueriesData<AddressNonNullable[]>(queries.addresses.all, addresses => {
        const { cloned: tempAddresses } = useCloned(addresses, { manual: true });
        if (tempAddresses.value) {
          tempAddresses.value.push(convertAddressIntoState(result.data));
        }
        return tempAddresses.value;
      });
      queryClient.invalidateQueries(queries.addresses.all);
    },
  });
};

export default useSaveAddress;
