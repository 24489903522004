import { BodyType } from './client';
import { PutApiV2AddressesIdBody, putApiV2AddressesId } from './pensa';

type UpdateAddressParams = {
  id: string;
} & BodyType<PutApiV2AddressesIdBody>;

export const updateAddress = async ({ id, ...address }: UpdateAddressParams) => {
  const result = await putApiV2AddressesId(id, address);
  return result.data;
};
