import { InjectionKey, Ref } from 'vue';
import { FingerprintPluginState } from '@/plugins/fingerprintjs';
import { PaymentMethod } from '@/model';

export const SELECTED_MENU: InjectionKey<Ref<string>> = Symbol('menus.selectedWeek');
export const EDIT_ORDER_ADDRESS_ID: InjectionKey<Ref<string>> = Symbol('upcoming.edit.addressId');
export const EDIT_ORDER_DELIVERY_DATE: InjectionKey<Ref<string>> = Symbol(
  'upcoming.edit.deliveryDate'
);

export const EDIT_ORDER_ORDER_ID: InjectionKey<Ref<string>> = Symbol('upcoming.edit.orderId');

export const HANDLE_SEE_SIDES: InjectionKey<() => void> = Symbol('menus.viewSides');

export const FINGERPRINT_PLUGIN_STATE: InjectionKey<FingerprintPluginState> = Symbol(
  'fingerprint.state'
);
export const FINGERPRINT_PLUGIN_LOAD: InjectionKey<() => void> = Symbol('fingerprint.load');

export const IS_CHECKOUT_STRIPE_INPUT_OPEN: InjectionKey<Ref<boolean>> = Symbol(
  'checkout.stripe-input.visible'
);
export const CHECKOUT_SELECTED_PAYMENT_METHOD: InjectionKey<
  Ref<PaymentMethod | undefined>
> = Symbol('checkout.selected-card');

export const IS_CHECKOUT_CARD_INPUT_VALID: InjectionKey<Ref<boolean>> = Symbol(
  'checkout.stripe-input.valid'
);

export const SET_CHECKOUT_LOADING: InjectionKey<(value: boolean) => void> = Symbol(
  'checkout.toggleLoading'
);

interface DixaControls {
  show: () => void;
  hide: () => void;
  open: () => void;
  close: () => void;
  load: () => void;
  claimIdentity: ({ name, email }: { name: string; email: string }) => void;
}

export const DIXA_CONTROLS: InjectionKey<DixaControls> = Symbol('dixa.controls');
export const DIXA_LOADED: InjectionKey<Ref<boolean>> = Symbol('dixa.loaded');

export const DELIVERY_FREQUENCY_LOCAL: InjectionKey<Ref<number>> = Symbol(
  'delivery-frequency.local'
);
