<template>
  <div
    v-if="shouldShowStagingBanner"
    id="staging-banner"
    class="flex items-center justify-center px-6 py-1 font-semibold text-white bg-red-500"
  >
    <ExclamationIcon class="mr-4 text-red-900 size-12 lg:size-6" />
    <div class="flex flex-col items-center justify-center lg:flex-row">
      <span class="font-semibold truncate">You are viewing the demo website</span>
      <a class="ml-2 text-xs sm:block" href="https://plan.pastaevangelists.com/">
        Looking for the live site?
        <span class="underline">Click here</span>
      </a>
    </div>
  </div>
  <MarketingBanners v-if="pastaNowBasketStore.fulfillmentType !== 'eat_in'" />
  <div class="relative bg-sandstone">
    <header
      class="relative z-20 flex items-center justify-center space-x-6"
      :class="{
        'lg:bg-transparent': hasTransparentNavbar,
      }"
    >
      <NavBar @open-modal="isModalOpen = true" />
    </header>
    <main>
      <FlashMessage />
      <router-view></router-view>
      <HowItWorksModal v-model="isModalOpen" />
    </main>
  </div>
  <template v-if="isLocalhost">
    <VueQueryDevtools />
  </template>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import usePreventScroll from '@/composables/usePreventScroll';
  import useRefreshDetails from '@/composables/useRefreshDetails';
  import useSelectedSubStorage from './composables/useSelectedSubStorage';
  import useExistingCustomerStorage from './composables/useExistingCustomerStorage';
  import useNewCustomerTrigger from './composables/useNewCustomerTrigger';
  import { ExclamationIcon } from '@heroicons/vue/outline';
  import useMarketingBannersStore from '@/stores/marketingBannersStore';
  import useTransparentNavbar from './composables/useTransparentNavbar';
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
  import useABTestDimension from './composables/useABTestDimension';
  import usePastaNowBasket from './stores/pastaNowBasketStore';
  import useAssignDixaUser from './composables/useAssignDixaUser';

  const marketingBannersStore = useMarketingBannersStore();
  const { hasTransparentNavbar } = useTransparentNavbar();
  const pastaNowBasketStore = usePastaNowBasket();

  marketingBannersStore.getMarketingBanners();
  const isModalOpen = ref(false);
  usePreventScroll(isModalOpen);
  useRefreshDetails();
  useSelectedSubStorage();
  useExistingCustomerStorage();
  useNewCustomerTrigger();
  useAssignDixaUser();
  useABTestDimension('NEWPENSAAPI');

  const shouldShowStagingBanner = Boolean(import.meta.env.VITE_SHOW_STAGING_BANNER);

  const isLocalhost = window.location.hostname === 'localhost';
</script>
