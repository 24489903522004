import { Normalize } from '@pasta-evangelists/pasta-types';
import normalize from '@/utils/normalize';
import { ApiResponse, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { BodyType } from './client';
import { normalizeTakeawayOrder } from '@/utils/takeaway';
import {
  OrderCreationResponse,
  OrderObject,
  Orders,
  PostApiV2OrdersBody,
  PostApiV2OrdersOrderIdDeliveryDateBody,
  PostApiV2OrdersOrderIdFeedbackBody,
  PostApiV2OrdersRecipeKitsBody,
  PostApiV2PreviewOrderBody,
  PutApiV2OrdersIdBody,
  PutApiV2ScheduleWeeksIdBody,
  ShipmentObject,
  getApiV2Orders,
  getApiV2OrdersId,
  getApiV2OrdersLive,
  getApiV2ScheduleWeeks,
  postApiV2CustomersCreditVoucher,
  postApiV2Orders,
  postApiV2OrdersOrderIdChefSelections,
  postApiV2OrdersOrderIdDeliveryDate,
  postApiV2OrdersOrderIdFeedback,
  postApiV2OrdersOrderIdVoucher,
  postApiV2OrdersRecipeKits,
  postApiV2Payments,
  postApiV2PreviewOrder,
  postApiV2ProductRatings,
  postApiV2ScheduleWeeksSubscriptionScheduleWeekIdChefSelections,
  putApiV2OrdersId,
  putApiV2OrdersOrderIdShippingAddressId,
  putApiV2ScheduleWeeksId,
} from './pensa';
import * as Sentry from '@sentry/vue';

export const upcomingOrders = async () => {
  const result = await getApiV2Orders({
    'filter[delivery_date]': 'upcoming',
    'filter[type]': 'ShopifyOrder',
    per_page: '3000',
  });

  const normalizedOrders = normalize(result);

  if (!normalizedOrders) return null;

  if (!Object.keys(normalizedOrders).length) return null;
  return normalizedOrders;
};

interface GetPastOrdersParams {
  page: number;
  perPage?: number;
}

export const getPastOrders = async ({ page, perPage = 20 }: GetPastOrdersParams) => {
  const result = await getApiV2Orders({
    'filter[delivery_date]': 'past',
    'filter[exclude_states]': 'skipped,dropped,payment_pending',
    page: page.toString(),
    per_page: perPage?.toString(),
  });

  return {
    normalizedOrders: normalize(result),
    meta: result.meta,
  };
};

export const getOneRandomPastOrder = async (): ApiResponse<Normalize<ObjectToCamel<Orders>>> => {
  try {
    const result = await getApiV2Orders({
      'filter[delivery_date]': 'past',
      page: '1',
      per_page: '1',
    });

    return {
      data: normalize(result),
      error: null,
    };
  } catch (e) {
    return { data: null, error: { message: 'We were unable to process your request' } };
  }
};

export const getScheduleWeeks = async () => {
  const result = await getApiV2ScheduleWeeks();
  return normalize(result);
};

type UpdateUpcomingOrderParams = {
  orderId: string;
  params: BodyType<PutApiV2OrdersIdBody>;
};

export const updateUpcomingOrders = async ({ orderId, params }: UpdateUpcomingOrderParams) => {
  const result = await putApiV2OrdersId(orderId, params);

  return result;
};

export const createUpcomingOrder = (params: BodyType<PostApiV2OrdersBody>) => {
  return postApiV2Orders(params);
};

interface ChangeUpcomingOrderDeliveryDateParams {
  orderId: string;
  params: BodyType<PostApiV2OrdersOrderIdDeliveryDateBody>;
}

export const changeUpcomingOrderDeliveryDate = async ({
  orderId,
  params,
}: ChangeUpcomingOrderDeliveryDateParams) => {
  const result = await postApiV2OrdersOrderIdDeliveryDate(orderId, params);

  return result.data;
};

interface ChangeUpcomingDeliveryAddress {
  orderId: string;
  addressId: string;
}

export const changeUpcomingOrderDeliveryAddress = async ({
  addressId,
  orderId,
}: ChangeUpcomingDeliveryAddress) => {
  const result = await putApiV2OrdersOrderIdShippingAddressId(orderId, addressId);

  return result;
};

const pickForMeAndCreateOrder = async (scheduleWeekId: string) => {
  const result = await postApiV2ScheduleWeeksSubscriptionScheduleWeekIdChefSelections(
    scheduleWeekId
  );

  return normalize(result);
};

const pickForMeAndUseOrderId = async (orderId: string) => {
  const result = await postApiV2OrdersOrderIdChefSelections(orderId);

  return normalize(result);
};

interface PickForMeParams {
  scheduleWeekId: string;
  orderId: string | null;
}

export const pickForMe = async ({ scheduleWeekId, orderId = null }: PickForMeParams) => {
  if (orderId) {
    return pickForMeAndUseOrderId(orderId);
  }
  return pickForMeAndCreateOrder(scheduleWeekId);
};

export interface RatingParams {
  lineItemId: string;
  rating: number;
  notes: string;
}

export const rateProduct = async (params: RatingParams) => {
  const result = await postApiV2ProductRatings(params);

  return result.data;
};

export interface GiveFeedbackParams {
  orderId: string;
  params: BodyType<PostApiV2OrdersOrderIdFeedbackBody>;
}

export const giveFeedback = async ({ orderId, params }: GiveFeedbackParams) => {
  const result = await postApiV2OrdersOrderIdFeedback(orderId, params);

  return result;
};

interface ApplyVoucherParams {
  orderId: string;
  code: string;
}

export const applyVoucher = async ({ orderId, code }: ApplyVoucherParams) => {
  const result = await postApiV2OrdersOrderIdVoucher(orderId, { code: code.toUpperCase() });

  return result;
};

type ChangeDeliveryDateForScheduleWeekParams = {
  scheduleWeekId: string;
} & BodyType<PutApiV2ScheduleWeeksIdBody>;

export const changeDeliveryDateForScheduleWeek = async ({
  scheduleWeekId,
  ...params
}: ChangeDeliveryDateForScheduleWeekParams) => {
  return putApiV2ScheduleWeeksId(scheduleWeekId, params);
};

export const applyGiftOrCreditVoucher = async (code: string) => {
  const result = await postApiV2CustomersCreditVoucher({ code: code.toUpperCase() });

  return result.data;
};

interface CreatePaymentForOrderParams {
  orderId: string;
  paymentMethodId?: string;
  paymentMethodType?: 'CreditCard';
  paymentProcessorName?: 'Stripe';
}

export const createPaymentForOrder = async ({
  orderId,
  paymentMethodId,
  paymentMethodType = 'CreditCard',
  paymentProcessorName = 'Stripe',
}: CreatePaymentForOrderParams) => {
  const params = paymentMethodId
    ? { paymentMethodId, orderId }
    : { orderId, paymentMethodType, paymentProcessorName };

  const result = await postApiV2Payments(params);
  return result;
};

export const getMostRecentOrderForSubscription = async (subscriptionId: string) => {
  const result = await getApiV2Orders({
    'filter[subscription_id]': subscriptionId,
    'filter[delivery_date]': 'past',
    page: '1',
    per_page: '1',
  });

  if (result.data.length === 0) {
    return null;
  }

  return normalize(result);
};

export const previewOrder = async (params: BodyType<PostApiV2PreviewOrderBody>) => {
  const result = await postApiV2PreviewOrder(params);

  return result;
};

export const getTakeawayOrderById = async (orderId: string) => {
  try {
    const result = await getApiV2OrdersId(orderId);

    return normalizeTakeawayOrder(result.data.id, normalize(result));
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};

export const getLiveOrders = async () => {
  const result = await getApiV2OrdersLive();
  if (!result.data || !result.data || !result.data?.length) return null;

  const order: Record<string, ObjectToCamel<OrderObject>> = {};

  result.data
    .filter(_order => _order.attributes.fulfillmentType !== 'eat_in')
    .forEach(_order => {
      order[_order.id] = _order;
    });

  const shipment: Record<string, ObjectToCamel<ShipmentObject>> = {};

  result.included?.forEach(includedItem => {
    if (includedItem.type === 'shipment') {
      shipment[includedItem.id] = includedItem;
    }
  });

  return { order: normalize(result), shipment };
};

export const createRecipeKitOrder = async (
  params: BodyType<PostApiV2OrdersRecipeKitsBody>
): ApiResponse<OrderCreationResponse> => {
  try {
    const result = await postApiV2OrdersRecipeKits(params);
    return { data: result, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', params);
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Order Failed'));
    });
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};

export const getLiveTakeawayOrders = async () => {
  const result = await getApiV2OrdersLive();

  return normalize(result);
};

export const getFailedOrders = async () => {
  try {
    const result = await getApiV2Orders({
      'filter[exclude_states]':
        'skipped,dropped,cancelled,billed,created,delivered,submitted,accepted,packed,shipped,ready_for_pickup',
    });

    return normalize(result);
  } catch (e) {
    throw new Error('We were unable to process your request');
  }
};
