import currentCustomerStore from '@/stores/currentCustomerStore';
import Axios, { AxiosResponse, isAxiosError } from 'axios';
import { camelizeKeys } from 'humps';
import { LocationQuery } from 'vue-router';
import useAddressStore from '@/stores/addressStore';
import useSubscriptionStore from '@/stores/subscriptionStore';
import useOrdersStore from '@/stores/ordersStore';
import usePaymentStore from '@/stores/paymentStore';
import useScheduleStore from '@/stores/scheduledStore';
import { ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { v4 as uuidv4 } from 'uuid';
import { Product, ProductVariant } from '@pasta-evangelists/pasta-types';
import {
  KeyOfSectionTitles,
  foodFilterDictionary,
  ingredientTags,
  sectionTitles,
} from '@/constants/menus';
import { GraphQLClient } from 'graphql-request';
import useWeeklyMenuStore from '@/stores/weeklyMenusStore';
import { parsePhoneNumber } from 'libphonenumber-js';
import { trackCustomEvent } from './analytics';
import { SelectedModifier } from './types';
import { Coordinates } from '@/types';
import { LineItemObject, SubscriptionObject } from '@/api/pensa';
import { BasketItem, NormalizedOrder, TransformedSubscription } from '@/model';

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
  headers: {
    'x-branch': 'PNIH',
  },
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type']?.includes('application/json')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = camelizeKeys(response.data as any);
    }
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      currentCustomerStore().$reset();
    } else if (isAxiosError<{ message: string }>(error)) {
      trackCustomEvent('error_reported', {
        error_type: error.response?.data?.message,
      });
    }
    throw error;
  }
);

export const shopifyGraphQLClient = new GraphQLClient(import.meta.env.VITE_STOREFRONT_ENDPOINT, {
  headers: {
    'X-Shopify-Storefront-Access-Token': import.meta.env.VITE_STOREFRONT_TOKEN,
  },
});

export const scrollTo = (element: HTMLElement, scrollPixels: number, duration: number) => {
  const scrollPos = element.scrollLeft;
  // Condition to check if scrolling is required
  if (
    !(
      (scrollPos === 0 || scrollPixels > 0) &&
      (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0)
    )
  ) {
    // Get the start timestamp
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const scroll = (timestamp: number) => {
      //Calculate the timeelapsed
      const timeElapsed = timestamp - startTime;
      //Calculate progress
      const progress = Math.min(timeElapsed / duration, 1);
      //Set the scrolleft
      element.scrollLeft = scrollPos + scrollPixels * progress;
      //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
      if (timeElapsed < duration) {
        //Request for animation
        window.requestAnimationFrame(scroll);
      } else {
        return;
      }
    };

    //Call requestAnimationFrame on scroll function first time
    window.requestAnimationFrame(scroll);
  }
};

export const phoneValidatorRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

export const getAllUserInfo = ({ forced }: { forced?: boolean } = {}) => {
  const customerStore = currentCustomerStore();
  const addressStore = useAddressStore();
  const ordersStore = useOrdersStore();
  const subscriptionStore = useSubscriptionStore();
  const paymentStore = usePaymentStore();
  const scheduleStore = useScheduleStore();
  const weeklyMenusStore = useWeeklyMenuStore();

  if (!customerStore.email || forced) customerStore.getUserDetails();
  if (!addressStore.addresses.length || forced) addressStore.loadAddresses();
  if (!ordersStore.upcoming || forced) ordersStore.getUpcomingOrders();
  if (!subscriptionStore.normalizedSubscriptions || forced) subscriptionStore.getSubscriptions();
  if (!paymentStore.paymentMethods.length || forced) paymentStore.getPaymentMethods();
  if (!weeklyMenusStore.products || Object.keys(weeklyMenusStore.products).length === 0 || forced)
    weeklyMenusStore.fetchMenus();
  scheduleStore.getSchedule();
};

interface BasketItemReducerParams {
  mealWeek: string;
  type: 'SubscriptionBasketItem' | 'OneOffBasketItem';
}

export const convertProductItemIntoBasketItemReducer = ({
  mealWeek,
  type,
}: BasketItemReducerParams) => (
  accumulator: BasketItem[],
  lineItem: NormalizedOrder['products'][number]
): BasketItem[] => {
  const parsedItem: BasketItem = {
    type: 'basket_item',
    id: lineItem.id,
    attributes: {
      discount: lineItem.discount,
      imageUrl: lineItem.imageUrl,
      isDouble: !lineItem.variantTitle?.includes('Regular'),
      mealWeek: mealWeek,
      price: lineItem.price,
      quantity: lineItem.quantity,
      title: lineItem.title,
      productVariantId: lineItem.productVariantId as number,
      productVariantExtId: lineItem.productVariantExtId,
      productId: lineItem.productId as number,
      isAddon: lineItem.isAddon,
      type,
    },
  };
  accumulator.push(parsedItem);
  return accumulator;
};

export interface ConvertProductVariantIntoLineItemParams {
  variant: ProductVariant;
  quantity: number;
  orderId: string;
  product: Product;
}

export const convertProductVariantIntoLineItem = ({
  orderId,
  variant,
  quantity,
  product,
}: ConvertProductVariantIntoLineItemParams): ObjectToCamel<LineItemObject> => {
  return {
    id: uuidv4(),
    type: 'line_item',
    attributes: {
      productVariantId: Number(variant.id), //TODO check if this is broken
      price: variant.price,
      quantity: quantity,
      sku: '',
      title: product.title,
      variantTitle: !variant.isDouble ? 'Regular' : 'Double Portion (Serves 2)',
      name: product.title,
      discount: '0.0',
      imageUrl: product.featuredImageUrl,
      tags: product.tags,
      productVariantExtId: variant.id,
      productId: parseInt(variant.id, 10),
      total: `${parseFloat(variant.price) * quantity}`,
      isAddon: false,
      parentLineItem: null,
    },
    relationships: {
      subItems: { data: [] },
      order: {
        data: {
          id: orderId,
          type: 'order',
        },
      },
      product: {
        data: {
          id: product.id,
          type: 'product',
        },
      },
      productRating: {
        data: null,
      },
      variant: {
        data: {
          id: variant.id,
          type: 'product_variant',
        },
      },
      refundLineItems: { data: [] },
    },
  };
};

export const convertFoodParamIntoFilterTag = (foodParam: string | null) => {
  switch (foodParam) {
    case 'meat':
      return 'Meat & Fish';
    case 'fish':
      return 'Meat & Fish';
    case 'vegetarian':
      return 'Vegetarian';
    case 'free':
      return 'Free From';
    case 'sides':
      return 'Sides';
    case 'desserts':
      return 'Desserts';
    default:
      return null;
  }
};

export const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const getProductCategoryFromTags = (tags: string[]) => {
  const cleanedTags = tags.filter(tag => ingredientTags.includes(tag));
  return Object.entries(foodFilterDictionary).reduce((acc, curr) => {
    const [category, tags] = curr;
    if (cleanedTags.some(tag => tags.includes(tag))) {
      return category;
    }
    return acc;
  }, '');
};

export const getProductCategoriesFromTags = (tags: string[]) => {
  const cleanedTags = tags.filter(
    tag =>
      ingredientTags.includes(tag) ||
      Object.values(foodFilterDictionary).some(element => element.includes(tag))
  );
  if (cleanedTags.length === 0) cleanedTags.push('section-specials');
  const categories = Object.entries(foodFilterDictionary).reduce((acc: string[], curr) => {
    const [category, tags] = curr;
    if (cleanedTags.some(tag => tags.includes(tag))) {
      return [...acc, category];
    }
    return acc;
  }, []);
  return categories.length === 0 ? ['Special'] : categories;
};

export const getSectionTitleFromTags = (tags: string[]) => {
  const cleanedTags = tags.filter(
    tag =>
      Object.keys(sectionTitles).includes(tag) ||
      Object.values(foodFilterDictionary).some(element => element.includes(tag))
  );
  if (cleanedTags.length === 0) cleanedTags.push('section-specials');
  return Object.keys(sectionTitles)
    .filter(title => cleanedTags.includes(title))
    .map(element => sectionTitles[element as KeyOfSectionTitles]);
};

export const filterParams = (params: LocationQuery) => {
  if (params.vw && typeof params.vw === 'string') {
    const filter = convertFoodParamIntoFilterTag(params.vw) as keyof typeof foodFilterDictionary;

    return filter || '';
  }
  return undefined;
};

export const convertOrderStateIntoStatus = (state: string) => {
  const ignoreCasing = state.toLocaleLowerCase();
  switch (ignoreCasing) {
    case 'paid':
    case 'billed':
    case 'packed':
    case 'submitted':
    case 'accepted':
      return 'Is being prepared';
    case 'shipped':
      return 'Is out for delivery';
    case 'payment_pending':
    case 'not_authorized':
      return 'Payment failed';
    case 'dropped':
      return 'Unable to process';
    default:
      return state.charAt(0).toUpperCase() + state.slice(1);
  }
};

export const convertToPrecisionOfTwo = (value: number) => {
  return parseFloat(value.toFixed(2));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function chain(...callbacks: any[]): (...args: any[]) => void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args);
      }
    }
  };
}

// Sets a CSS property on an element, and returns a function to revert it to the previous value.
export const setStyle = (
  element: HTMLElement,
  style: keyof CSSStyleDeclaration,
  value: string | null
) => {
  const cur = element.style[style];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element.style[style as any] = value as any;
  return () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (element.style[style as any] as any) = cur;
  };
};

export const isScrollable = (node: Element): boolean => {
  const style = window.getComputedStyle(node);
  return /(auto|scroll)/.test(style.overflow + style.overflowX + style.overflowY);
};

interface CompareState {
  id: string;
  name: string | null;
  state: string | null;
}

const compareSubscriptionsStates = (a: CompareState, b: CompareState) => {
  if (a.state === 'active' && b.state !== 'active') {
    return -1;
  }

  if (a.state !== 'active' && b.state === 'active') {
    return 1;
  }

  if (a.state === 'failed' && b.state !== 'failed') {
    return -1;
  }

  if (a.state !== 'failed' && b.state === 'failed') {
    return 1;
  }

  if (a.state === 'paused' && b.state !== 'paused') {
    return -1;
  }

  if (a.state !== 'paused' && b.state === 'paused') {
    return 1;
  }

  if (a.state === 'cancelled' && b.state !== 'cancelled') {
    return -1;
  }

  if (a.state !== 'cancelled' && b.state === 'cancelled') {
    return 1;
  }

  if (a.state === 'created' && b.state !== 'created') {
    return -1;
  }

  if (a.state !== 'created' && b.state === 'created') {
    return 1;
  }

  const nameA: string = a.name || `Pasta Plan ${a.id}`;
  const nameB: string = b.name || `Pasta Plan ${b.id}`;

  return +nameA - +nameB;
};

export const sortTransformedSubscriptionByState = (
  a: TransformedSubscription,
  b: TransformedSubscription
) => {
  return compareSubscriptionsStates(
    { id: a.id, name: a.name, state: a.state },
    { id: b.id, name: b.name, state: b.state }
  );
};

export const sortSubscriptionByState = (
  a: ObjectToCamel<SubscriptionObject>,
  b: ObjectToCamel<SubscriptionObject>
) => {
  return compareSubscriptionsStates(
    { id: a.id, name: a.attributes.name, state: a.attributes.state },
    { id: b.id, name: b.attributes.name, state: b.attributes.state }
  );
};

export const isChefSpecialsSection = (tags: string[]) => {
  return (
    !tags.includes('section-favourites') &&
    !tags.includes('section-accompaniments') &&
    !tags.includes('section-desserts') &&
    !tags.includes('section-kids')
  );
};

export const getNumberOfProductsForTag = (menu: Product[], filter?: string) => {
  if (!filter || !(filter in foodFilterDictionary)) return menu.length;
  return menu.filter(prod =>
    prod.tags.some(tag =>
      foodFilterDictionary[filter as keyof typeof foodFilterDictionary].includes(tag)
    )
  ).length;
};

export const getParamsFromObject = (params: Record<string, string | number | boolean>) =>
  '?' +
  Object.keys(params)
    .map(param => `${param}=${encodeURIComponent(params[param])}`)
    .join('&');

export const checkPaymentFail = (order: NormalizedOrder): boolean => {
  const hasFailedState =
    order.attributes.state === 'not_authorized' || order.attributes.state === 'payment_pending';

  return Boolean(hasFailedState);
};

export const validationFunctionForPhone = (phone: string | undefined) => {
  if (phone) {
    try {
      if (phone.startsWith('0')) {
        return parsePhoneNumber(`+44${phone.substring(1)}`)?.isValid() ?? false;
      }
      return parsePhoneNumber(phone)?.isValid() ?? false;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const ukPhoneValidation = (phone: string | undefined) => {
  if (phone) {
    try {
      if (phone.startsWith('00')) {
        if (!phone.startsWith('0044')) {
          return false;
        }

        const parsedPhoneNumber = `+44${phone.substring(4)}`;
        const isFromUk = parsePhoneNumber(parsedPhoneNumber)?.country === 'GB';

        return (parsePhoneNumber(parsedPhoneNumber)?.isValid() && isFromUk) ?? false;
      }
      if (phone.startsWith('0')) {
        const parsedPhoneNumber = `+44${phone.substring(1)}`;
        const isFromUk = parsePhoneNumber(parsedPhoneNumber)?.country === 'GB';
        return (parsePhoneNumber(parsedPhoneNumber)?.isValid() && isFromUk) ?? false;
      }

      return parsePhoneNumber(phone)?.isValid() && parsePhoneNumber(phone)?.country === 'GB';
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const plusSignEmailValidation = (email: string | undefined) => {
  if (!import.meta.env.VITE_IS_PROD) return true;
  if (email) {
    return !email.includes('+');
  }
  return false;
};

export const metersToMiles = (meters: number) => {
  return (meters * 0.000621371192).toFixed(2);
};

export const convert24HourToAMPM = (time: string) => {
  const splitTime = time.split(':');
  const hour = parseInt(splitTime[0]);

  return `${hour > 12 ? hour - 12 : hour}:${splitTime[1]}${hour > 11 ? 'pm' : 'am'}`;
};

export const convertCheckoutUrlToMenuType = (url?: string, isAbbreviation = false) => {
  if (!url) return undefined;
  if (url.includes('checkout')) return isAbbreviation ? 'RK' : 'Recipe Kits New';
  else if (url.includes('pasta-now')) return isAbbreviation ? 'TW' : 'Takeaway';
  else return isAbbreviation ? 'OO' : 'One-off';
};

export const convertCheckoutTypeToMenuType = (checkoutType?: string | boolean) => {
  if (!checkoutType) return undefined;
  switch (checkoutType) {
    case 'pasta-now':
      return 'Takeaway';
    case 'subscription':
      return 'Recipe Kits New';
    default:
      return 'One-off';
  }
};

export const checkIsDouble = (modifiers: SelectedModifier[]) => {
  return !!modifiers.find(modifier => {
    if (!modifier.takeawayProduct.attributes.title) return false;
    return (
      modifier.takeawayProduct.attributes.title.toLocaleLowerCase().indexOf('grande') > -1 ||
      modifier.takeawayProduct.attributes.title.toLocaleLowerCase().indexOf('bigger') > -1 ||
      modifier.takeawayProduct.attributes.title.toLocaleLowerCase().indexOf('double') > -1
    );
  });
};

export const filterOutHiddenProductsOrInvalidBundles = (product: Product, bundles?: Product[]) => {
  if (product.tags.includes('hide-from-menu')) return false;
  if (product.productType === 'Sub - Gift Box') return false;
  if (product.productType !== 'Sub - Bundle' || !bundles) return true;
  const bundle = bundles.find(bundle => bundle.id === product.id);
  return !!bundle;
};

export const numberToWord = (num: number): string => {
  switch (num) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    default:
      return '';
  }
};

export const findDistanceInMilesBetweenTwoGpsCoordinates = (
  source: Coordinates,
  destination: Coordinates
) => {
  const R = 3959; // Earth's radius in miles
  const lat1Rad = (parseFloat(source.lat) * Math.PI) / 180;
  const lon1Rad = (parseFloat(source.lon) * Math.PI) / 180;
  const lat2Rad = (parseFloat(destination.lat) * Math.PI) / 180;
  const lon2Rad = (parseFloat(destination.lon) * Math.PI) / 180;

  const deltaLat = lat2Rad - lat1Rad;
  const deltaLon = lon2Rad - lon1Rad;

  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in miles

  return distance.toFixed(2);
};

export const NOOP = () => {};
