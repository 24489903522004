import normalize from '@/utils/normalize';
import { BodyType } from './client';
import {
  PostApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody,
  PostApiV2SubscriptionsSubscriptionIdPauseBody,
  PutApiV2SubscriptionsIdBody,
  PutApiV2SubscriptionsIdScheduleBody,
  getApiV2Ingredients,
  getApiV2Subscriptions,
  postApiV2SubscriptionsSubscriptionIdActivate,
  postApiV2SubscriptionsSubscriptionIdIngredientPreferences,
  postApiV2SubscriptionsSubscriptionIdPause,
  putApiV2SubscriptionsId,
  putApiV2SubscriptionsIdSchedule,
} from './pensa';

export interface SubscriptionParams {
  name: string | null;
  deliveryNote: string;
  shippingAddressId: string | null;
  billingAddressId: string | null;
  paymentMethodId: string | null;
  numberOfRecipes: number | null;
  cocktails: boolean | null;
  numberOfCocktails: number;
  glutenFree: boolean;
  doublePortion: boolean | null;
  pork: boolean;
  beef: boolean;
  lamb: boolean;
  game: boolean;
  crustaceans: boolean;
  fish: boolean;
  nuts: boolean;
  vegetarian: boolean;
  vegan: boolean;
  molluscs: boolean;
  recipeVariety: boolean;
}

export interface ScheduleParams {
  deliveryDay?: string;
  frequency?: number;
}

export interface PauseReason {
  pauseReason: string;
  pauseNote: string;
}

export const getSubscriptions = async () => {
  const result = await getApiV2Subscriptions();
  return normalize(result);
};

interface UpdateSubscriptionParams {
  subId: string;
  params: BodyType<PutApiV2SubscriptionsIdBody>;
}

export const updateSubscription = async ({ subId, params }: UpdateSubscriptionParams) => {
  const result = await putApiV2SubscriptionsId(subId, params);

  return result.data;
};

interface UpdateSubscriptionSchedule {
  subId: string;
  params: BodyType<PutApiV2SubscriptionsIdScheduleBody>;
}

export const updateSubscriptionSchedule = async ({ subId, params }: UpdateSubscriptionSchedule) => {
  const result = putApiV2SubscriptionsIdSchedule(subId, params);

  return result;
};

interface PauseSubscriptionParams {
  subId: string;
  params: BodyType<PostApiV2SubscriptionsSubscriptionIdPauseBody>;
}

export const pauseSubscription = async ({ subId, params }: PauseSubscriptionParams) => {
  const result = await postApiV2SubscriptionsSubscriptionIdPause(subId, params);

  return normalize(result);
};

export const activateSubscription = async (subId: string) => {
  const result = await postApiV2SubscriptionsSubscriptionIdActivate(subId);

  return normalize(result);
};

export const getIngredients = async () => {
  const result = await getApiV2Ingredients();

  return result.data;
};

interface UpgradeIngredientsParams {
  subscriptionId: string;
  params: BodyType<PostApiV2SubscriptionsSubscriptionIdIngredientPreferencesBody>;
}

export const updateIngredientsForSubscription = async ({
  subscriptionId,
  params,
}: UpgradeIngredientsParams) => {
  const result = await postApiV2SubscriptionsSubscriptionIdIngredientPreferences(
    subscriptionId,
    params
  );

  return result;
};
